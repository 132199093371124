import React, { useState, useEffect } from "react";
import { Loader2, Search } from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "./../../components/ui/dialog";
import { Input } from "./../../components/ui/input";
import { Checkbox } from "./../../components/ui/checkbox";
import { ScrollArea } from "./../../components/ui/scroll-area";
import { cn } from "../../lib/utils";
import { useGetCompanyRoutesForSelectQuery } from "../../api/busLineOfficeApi";
import { useOfficeUserAuth } from "../../api/officeAuth";
import { errorHandler } from "../../error/index";

interface Routes {
  id: string;
  name: string;
}

const RouteSelector = ({ onSelectionChange, isDialogOpen, setDialogOpen }) => {
  const [search, setSearch] = useState("");
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routes, setRoutes] = useState<Routes[]>([]);

  const { companyId } = useOfficeUserAuth();
  const { isLoading, isError, data, error } = useGetCompanyRoutesForSelectQuery(companyId);

  
  useEffect(() => {
    if (data?.companyData?.routes) {
      setRoutes(data.companyData.routes);
    }
  }, [data]);

  const filteredRoutes = React.useMemo(() => {
    return routes.filter((route) =>
      route.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [routes, search]);

  const handleCheckboxChange = (routeId: string) => {
    setSelectedRoute(selectedRoute === routeId ? null : routeId);
  };

  const handleConfirm = () => {
    const selectedRouteData = routes.find((r) => r.id === selectedRoute);
    onSelectionChange?.(selectedRouteData ? [selectedRouteData] : []);
    setDialogOpen(false);
  };

  useEffect(() => {
    if (isError && error) {
      errorHandler(error);
    }
  }, [error, isError]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="w-full justify-start text-left font-normal"
        >
          <Search className="mr-2 h-4 w-4" />
          Select Route {selectedRoute ? "(1 selected)" : "(None selected)"}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select Route</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <Search className="h-4 w-4 text-gray-500" />
            <Input
              placeholder="Search routes..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="flex-1"
            />
          </div>
          <ScrollArea className="h-72 rounded-md border p-2">
            {filteredRoutes.length > 0 ? (
              <div className="space-y-2">
                {filteredRoutes.map((route) => (
                  <div
                    key={route.id}
                    className={cn(
                      "flex items-center space-x-2 rounded-lg p-2",
                      "hover:bg-accent hover:text-accent-foreground"
                    )}
                  >
                    <label
                      htmlFor={`route-${route.id}`}
                      className="flex-1 cursor-pointer"
                    >
                      <div className="font-medium">{route.name}</div>
                    </label>
                    <Checkbox
                      id={`route-${route.id}`}
                      checked={selectedRoute === route.id}
                      onCheckedChange={() => handleCheckboxChange(route.id)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex h-full items-center justify-center text-sm text-gray-500">
                No routes found
              </div>
            )}
          </ScrollArea>
        </div>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <Button
            onClick={handleConfirm}
            className="bg-green-700"
            variant="outline"
          >
            Confirm {selectedRoute ? "(1 selected)" : "(None selected)"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <Loader2 className="h-8 w-8 animate-spin" />
    <span className="ml-2">Loading...</span>
  </div>
);

export default RouteSelector;
