import React, { useEffect,useMemo } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useLazyGetOwnerDataQuery } from "../../api/busLineUserApi";
import { useOwnerAuth } from "../../api/ownerAuth";
import { errorHandler } from "../../error/index";


export default function OwnerInputDropdown({
  value,
  onValueChange,
  placeHolder,
}) {
  const { email } = useOwnerAuth();
  const [getOwnerData, { isError, error, data }] = useLazyGetOwnerDataQuery();

  useEffect(() => {
    if (email) {
      getOwnerData(email);
    }
    if (isError) {
      errorHandler(error);
    }
  }, [email, error, getOwnerData, isError]);

  const uniqueVehicles = useMemo(() => {
    if (data?.reports) {
      const vehicleMap = new Map();
      data.reports.forEach((report) => {
        if (report.vehicle && !vehicleMap.has(report.vehicle.id)) {
          vehicleMap.set(report.vehicle.id, report.vehicle);
        }
      });
      return Array.from(vehicleMap.values());
    }
    return [];
  }, [data]);

  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className="w-64">
        <SelectValue placeholder={placeHolder} />
      </SelectTrigger>
      <SelectContent>
        {uniqueVehicles.length > 0 ? (
          uniqueVehicles.map((vehicle) => (
            <SelectItem key={vehicle.id} value={vehicle.registrationNumber}>
              {vehicle.registrationNumber}
            </SelectItem>
          ))
        ) : (
          <SelectItem value="none">No vehicles registered to this owner.</SelectItem>
        )}
      </SelectContent>
    </Select>
  );
}