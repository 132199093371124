import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogCancel,
} from "../../components/ui/alert-dialog";
import { errorHandler } from "../../error/index";
import { successFunctionDisplay } from "../../success/success";
import { useLazyDeleteCompanyVehicleQuery } from "../../api/busLineApiData";
import { useDispatch } from "react-redux";
import { setVehicleDeleteRefetchSlice } from "../../slices/vehicleDelete";
import { useAuth } from "../../api/getCredentials";

interface DeleteDialogProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  vehicleId: string;
}

export default function DeleteVehicleDialog({
  isOpen,
  setOpen,
  vehicleId,
}: DeleteDialogProps) {
  const { companyId } = useAuth();
  const dispatch = useDispatch();
  const [deleteSpecificCompanyRoute] = useLazyDeleteCompanyVehicleQuery();
  const handleDelete = async () => {
    const fireDeleteAction = await deleteSpecificCompanyRoute({
      companyId,
      vehicleId,
    });
    if ("data" in fireDeleteAction) {
      successFunctionDisplay("Vehicle deleted successfully.");
      dispatch(setVehicleDeleteRefetchSlice(true));
    } else {
      return errorHandler(fireDeleteAction.error);
    }
  };
  return (
    <>
      <AlertDialog open={isOpen} onOpenChange={setOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-2xl text-red-600">
              Delete?...!
            </AlertDialogTitle>
            <AlertDialogDescription className="text-lg">
              Are you sure you wanna complete this action?.The action cannot be
              undone once completed.
             <p className="font-bold">NOTE: DELETING THE VEHICLE DELETES RELATED DATA TOO.</p> 
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className={
                "bg-destructive hover:bg-destructive/80 text-destructive-foreground"
              }
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
