import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VehicleDeleteRefetchState {
  vehicleDeleteRefetchSlice: boolean | null;
}

const initialState: VehicleDeleteRefetchState = {
    vehicleDeleteRefetchSlice: null,
};

const vehicleDeleteRefetchSlice = createSlice({
  name: "vehicleDeleteRefetchSlice",
  initialState,
  reducers: {
    setVehicleDeleteRefetchSlice: (state, action: PayloadAction<boolean>) => {
      state.vehicleDeleteRefetchSlice = action.payload;
    },
  },
});

export const { setVehicleDeleteRefetchSlice } = vehicleDeleteRefetchSlice.actions;
export default vehicleDeleteRefetchSlice.reducer;

