import React, { useEffect, useState } from "react";
import { CompanyOfficesData, createColumns } from "./company_offices_column";
import { DataTable } from "../Re-Usables/data-table";
import { useAuth } from "../../../api/getCredentials";
import { useGetCompanyOfficesQuery } from "../../../api/busLineApiData";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { setOfficeUpdateRefetchSlice } from "../../../slices/officeUpdate";
import { RootState } from "../../../slices/types";
import { setOfficeDeleteRefetchSlice } from "../../../slices/officeDelete";

interface OfficeDataProps {
  openPasswordDialog: (officeId: string) => void;
  handleGenPasswordForOffice: (officeId: string) => void;
  handleGetBookingOfficeData: (officeId: string) => void;
  handleDeleteOffice: (officeId: string) => void;
}
export default function CompanyOfficeTableData({
  openPasswordDialog,
  handleGenPasswordForOffice,
  handleGetBookingOfficeData,
  handleDeleteOffice,
}: OfficeDataProps) {
  const dispatch = useDispatch();
  const { officeUpdateRefetchSlice } = useSelector(
    (state: RootState) => state.officeUpdate
  );
  const { officeDeleteRefetchSlice } = useSelector(
    (state: RootState) => state.officeDelete
  );
  const { companyId, token } = useAuth();
  const [pageIndex, setPageIndex] = useState(0);
  const {
    isLoading: getCompanyOfficesLoading,
    error: getCompanyOfficesError,
    data: companyOfficeData,
    isError,
    refetch,
  } = useGetCompanyOfficesQuery(
    { companyId, page: pageIndex + 1 },
    {
      skip: !token || !companyId,
    }
  );

  useEffect(() => {
    (async () => {
      if (officeUpdateRefetchSlice) {
        await refetch();
      }
      dispatch(setOfficeUpdateRefetchSlice(false));
    })();
  }, [dispatch, officeUpdateRefetchSlice, refetch]);

  useEffect(() => {
    (async () => {
      if (officeDeleteRefetchSlice) {
        await refetch();
      }
      dispatch(setOfficeDeleteRefetchSlice(false));
    })();
  }, [dispatch, officeDeleteRefetchSlice, refetch]);

  useEffect(() => {
    if (isError) {
      errorHandler(getCompanyOfficesError);
    }
  }, [getCompanyOfficesError, isError]);

  const prepareTableData = (): CompanyOfficesData[] => {
    if (
      !companyOfficeData ||
      !companyOfficeData.companyOffices ||
      !companyOfficeData.companyOffices.offices.length
    ) {
      return [];
    }

    return companyOfficeData.companyOffices.offices.map((office) => ({
      name: office.name,
      address: office.address,
      city: office.city,
      officeId: office.id,
    }));
  };

  if (getCompanyOfficesLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();
  const columns = createColumns({
    openPasswordDialog,
    handleGenPasswordForOffice,
    handleGetBookingOfficeData,
    handleDeleteOffice,
  });

  return (
    <div className="container mx-auto py-10">
      <DataTable
        columns={columns}
        data={tableData}
        pageCount={companyOfficeData?.totalPages ?? 1}
        pageIndex={pageIndex}
        onPaginationChange={setPageIndex}
      />
    </div>
  );
}
