import React, { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Card, CardContent } from "../../components/ui/card";
import { useGetSpecificVehicleDetailsQuery } from "../../../src/api/busLineApiData";
import { errorHandler } from "../../../src/error";
import { RootState } from "../../../src/slices/types";
import { useSelector } from "react-redux";

interface VehicleDetailsDialogProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  vehicleId: string;
}

interface VehicleDetails {
  collectiveData: {
    model: string;
    registrationNumber: string;
    capacity: number;
    owner: {
      email: string;
      name: string;
      phone: string;
    };
    company: {
      name: string;
    };
    driver: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
    };
  };
}

const VehicleDetailsDialog: React.FC<VehicleDetailsDialogProps> = ({
  isOpen,
  onClose,
  vehicleId,
}) => {
  const { error, data, refetch } = useGetSpecificVehicleDetailsQuery({
    vehicleId,
  });
  const [vehicleData, setVehicleData] = useState<VehicleDetails | null>();
  const { vehicleUpdateRefetchSlice } = useSelector(
    (state: RootState) => state.vehicleUpdate
  );
  useEffect(() => {
    if (data) {
      setVehicleData(data);
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  useEffect(() => {
    (async () => {
      if (!vehicleUpdateRefetchSlice) {
        refetch();
      }
    })();
  }, [refetch, vehicleUpdateRefetchSlice]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px] bg-white">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-bold text-blue-600">
            Vehicle Details
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 p-4">
          <Card className="bg-blue-50 border-blue-100">
            <CardContent className="p-6">
              <div className="grid grid-cols-2 gap-4">
                <DetailItem
                  label="Model"
                  value={vehicleData?.collectiveData?.model ?? "N/A"}
                />
                <DetailItem
                  label="Reg. Number"
                  value={
                    vehicleData?.collectiveData?.registrationNumber ?? "N/A"
                  }
                />
                <DetailItem
                  label="Capacity"
                  value={`${
                    vehicleData?.collectiveData?.capacity ?? "N/A"
                  } passengers`}
                />
                <DetailItem
                  label="Company"
                  value={vehicleData?.collectiveData?.company?.name ?? "N/A"}
                />
              </div>
            </CardContent>
          </Card>

          <Card className="bg-blue-50 border-blue-100">
            <CardContent className="p-6">
              <h3 className="text-lg font-semibold text-blue-600 mb-4">
                Owner Details
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <DetailItem
                  label="Name"
                  value={vehicleData?.collectiveData?.owner?.name ?? "N/A"}
                />
                <DetailItem
                  label="Email"
                  value={vehicleData?.collectiveData?.owner?.email ?? "N/A"}
                />
                <DetailItem
                  label="Phone"
                  value={vehicleData?.collectiveData?.owner?.phone ?? "N/A"}
                />
              </div>
            </CardContent>
          </Card>

          <Card className="bg-blue-50 border-blue-100">
            <CardContent className="p-6">
              <h3 className="text-lg font-semibold text-blue-600 mb-4">
                Driver Details
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <DetailItem
                  label="Name"
                  value={`${vehicleData?.collectiveData?.driver?.firstName} ${
                    vehicleData?.collectiveData?.driver?.lastName ?? "N/A"
                  }`}
                />
                <DetailItem
                  label="Email"
                  value={vehicleData?.collectiveData?.driver?.email ?? "N/A"}
                />
                <DetailItem
                  label="Phone"
                  value={
                    vehicleData?.collectiveData?.driver?.phoneNumber ?? "N/A"
                  }
                />
              </div>
            </CardContent>
          </Card>

          <div className="flex justify-center mt-4">
            <Button
              onClick={() => onClose(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-2"
            >
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

// Helper component for consistent detail rendering
const DetailItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <div className="flex flex-col">
    <span className="text-sm text-blue-500 font-medium">{label}</span>
    <span className="text-md font-semibold text-gray-800">{value}</span>
  </div>
);

export default VehicleDetailsDialog;
