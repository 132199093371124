import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LogOut, Settings, HomeIcon, BusFrontIcon, List } from "lucide-react";
import logo from "../../image/logo.png";
import LogoutDialog from "./LogoutDialog";

export default function OfficeSideBar() {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path ? "bg-blue-600 text-green" : "";
  };
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();

  const toggleLogoutDialog = () => setIsLogoutDialogOpen(!isLogoutDialogOpen);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };
  return (
    <div className="flex">
      <aside className="fixed top-0 left-0 h-full flex flex-col border-r bg-customLightBlue px-4 py-6 sm:px-6 md:w-60 lg:w-72 overflow-y-auto z-50">
        <div className="mb-8">
          <img src={logo} alt="Logo" className="h-24 w-38" />
        </div>
        <div className="mt-8 space-y-5">
          <div className="text-lg font-bold">Dashboard</div>
          <nav className="space-y-5">
            <Link
              to="/office/home"
              className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:bg-muted ${isActive(
                "/office/home"
              )}`}
              replace
            >
              <HomeIcon className="h-5 w-5" />
              Home
            </Link>
            <Link
              to="/office/schedule-list"
              className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:bg-muted ${isActive(
                "/office/schedule-list"
              )}`}
              replace
            >
              <List className="h-5 w-5" />
              Schedule List
            </Link>
            <Link
              to="/office/vehicle-departure"
              className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:bg-muted ${isActive(
                "/office/vehicle-departure"
              )}`}
              replace
            >
              <BusFrontIcon className="h-5 w-5" />
              Vehicle Departure
            </Link>
          </nav>
        </div>
        <div className="mt-auto space-y-2">
          <div className="text-lg font-bold">General</div>
          <nav className="space-y-1">
            <button
              className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:bg-muted w-full text-left ${
                isActive("/employee") ? "bg-muted" : ""
              }`}
              onClick={toggleLogoutDialog}
            >
              <LogOut className="h-5 w-5" />
              Logout
            </button>
            <Link
              to="/settings"
              className="flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:bg-muted"
            >
              <Settings className="h-5 w-5" />
              Settings
            </Link>
          </nav>
        </div>
      </aside>
      <main className="flex-1 ml-[15rem] lg:ml-[19rem] p-6 overflow-y-auto">
        {/* Main content goes here */}
      </main>
      <LogoutDialog
        isOpen={isLogoutDialogOpen}
        onClose={toggleLogoutDialog}
        onConfirm={handleLogout}
      />
    </div>
  );
}
