import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import AddBookingOffice from "./AddBookingOffice";
import { useAuth } from "../../api/getCredentials";
import { errorHandler } from "../../error";
import { useLazyGenerateOfficePasswordQuery } from "../../api/busLineApiData";
import PasswordDisplay from "./PasswordDisplay";
import CompanyOfficeTableData from "./CompanyOffices/company_offices_page";
import ExistingPasswordDisplay from "./ExistingPasswordDisplay";
import { useLazyGetGeneratedOfficePasswordQuery } from "../../api/busLineApiData";
import { useLazyGetOfficeDataQuery } from "../../api/busLineApiData";
import UpdateBookingOffice from "./UpdateBookingOffice";
import DeleteOfficeDialog from "../Custom/DeleteOfficeDialog";

interface PasswordMessage {
  message: string;
}
export default function CompanyListOffice() {
  const [isRetrievedPasswordDialogOpen, setRetrievedPasswordDialogOpen] =
    useState<boolean>(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [officeId, setOfficeId] = useState<string>();
  const [isUpdateBookingOfficeDialogOpen, setUpdateBookingOfficeDialogOpen] =
    useState<boolean>(false);
  const [retrievedPasswordData, setRetrievedPasswordData] =
    useState<PasswordMessage | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPassDialogOpen, setIsPassDialogOpen] = useState(false);
  const { companyId } = useAuth();
  const [passwordData, setPasswordData] = useState(null);
  const [
    generatePassword,
    { error, isError: generatePasswordError, data, isSuccess },
  ] = useLazyGenerateOfficePasswordQuery();
  const [
    getGeneratedPassword,
    {
      error: genPasswordError,
      isError: isGeneratePasswordError,
      data: genPasswordData,
      isSuccess: isGenPasswordSuccess,
    },
  ] = useLazyGetGeneratedOfficePasswordQuery();

  const [
    getOfficeData,
    { error: officeError, isError: isOfficeError, data: officeData },
  ] = useLazyGetOfficeDataQuery();
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const closePassDialog = () => {
    setIsPassDialogOpen(false);
    setPasswordData(null);
  };
  const handleGeneratePassword = (officeId: string) => {
    setPasswordData(null);
    generatePassword({
      companyId,
      officeId: officeId,
    });
  };

  const handleDeleteOffice = (officeId: string) => {
    setDeleteDialogOpen(false);
    setOfficeId(officeId);
    setDeleteDialogOpen(true);
  };

  const handleGetBookingOfficeData = async (officeId: string) => {
    setUpdateBookingOfficeDialogOpen(false);
    await getOfficeData({ officeId, companyId });
    setUpdateBookingOfficeDialogOpen(true);
    console.log(officeData);
  };

  const handleGenPasswordForOffice = async (officeId: string) => {
    setRetrievedPasswordData(null);
    setRetrievedPasswordDialogOpen(false);
    await getGeneratedPassword({
      companyId,
      officeId: officeId,
    });
    setRetrievedPasswordDialogOpen(true);
  };

  useEffect(() => {
    if (generatePasswordError) {
      errorHandler(error);
    }

    if (isSuccess && data) {
      setPasswordData(data);
      setIsPassDialogOpen(true);
    }
  }, [data, error, generatePasswordError, isSuccess]);

  useEffect(() => {
    if (isOfficeError) {
      errorHandler(officeError);
    }
  }, [isOfficeError, officeError]);

  useEffect(() => {
    if (isGeneratePasswordError) {
      errorHandler(genPasswordError);
    }
  }, [genPasswordError, isGeneratePasswordError]);

  useEffect(() => {
    if (isGenPasswordSuccess && genPasswordData) {
      setRetrievedPasswordData(genPasswordData);
      setRetrievedPasswordDialogOpen(true);
    }
  }, [genPasswordData, isGenPasswordSuccess]);

  const closeRetrievedPasswordDialog = () => {
    setRetrievedPasswordDialogOpen(false);
    setRetrievedPasswordData(null);
  };

  return (
    <div className="w-full max-w-full mx-auto px-4 sm:px-6 lg:px-8">
      <Button
        className="bg-customDarkBlue text-white mx-auto mt-4 block w-full sm:w-auto"
        onClick={openDialog}
      >
        Add Booking Office
      </Button>
      <CompanyOfficeTableData
        openPasswordDialog={handleGeneratePassword}
        handleGenPasswordForOffice={handleGenPasswordForOffice}
        handleGetBookingOfficeData={handleGetBookingOfficeData}
        handleDeleteOffice={handleDeleteOffice}
      />

      <AddBookingOffice isOpen={isDialogOpen} onClose={closeDialog} />
      {isSuccess && data && passwordData && (
        <PasswordDisplay
          isOpen={isPassDialogOpen}
          message={passwordData.message}
          password={passwordData.plaintextPassword}
          note={passwordData.note}
          onClose={closePassDialog}
        />
      )}
      {retrievedPasswordData && (
        <ExistingPasswordDisplay
          isOpen={isRetrievedPasswordDialogOpen}
          onClose={closeRetrievedPasswordDialog}
          message={retrievedPasswordData.message}
        />
      )}
      {officeData && (
        <UpdateBookingOffice
          isOpen={isUpdateBookingOfficeDialogOpen}
          office={officeData?.office}
          onClose={() => setUpdateBookingOfficeDialogOpen(false)}
        />
      )}
      <DeleteOfficeDialog
        officeId={officeId}
        isOpen={isDeleteDialogOpen}
        setOpen={() => setDeleteDialogOpen(false)}
      />
    </div>
  );
}
