import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  VehicleCapacity,
  VehicleCapacityError,
} from "../utility_components/utility_functions/utilityFunctions";
import { useDispatch } from "react-redux";
import { clearError } from "../../slices/errorSlice";
import { errorHandler } from "../../error/index";
import { successFunctionDisplay } from "../../success/success";
import { usePostDriverEndTripMutation } from "../../api/busLineUserApi";
import { useNormalUserAuth } from "../../api/NormalUserAuth";

export default function ResetModal({ isOpen, onClose, vehicleId }) {
  const dispatch = useDispatch();
  const [capacityData, setVehicleCapacity] = useState<VehicleCapacity>({
    vehicleCapacity: "",
  });
  const { userId, } = useNormalUserAuth();
  const [
    driverAndVehicleData,
  ] = usePostDriverEndTripMutation();
  const [errors, setErrors] = useState<VehicleCapacityError>({});
  const validateForm = () => {
    let newErrors: VehicleCapacityError = {};

    if (!capacityData.vehicleCapacity.trim())
      newErrors.vehicleCapacity = "Vehicle capacity is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setVehicleCapacity((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const result = await driverAndVehicleData({
        vehicleId,
        driverId: userId,
        vehicleCapacity: capacityData.vehicleCapacity,
      });
      if ("data" in result) {
        console.log("Vehicle reset successfully", result);
        capacityData.vehicleCapacity = "";
        successFunctionDisplay("Trip ended successfully");
        onClose();
      } else {
        capacityData.vehicleCapacity = "";
        onClose();
        return errorHandler(result.error);
      }
      onClose();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Enter Vehicle Capacity..
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue">
              <div>
                <Label htmlFor="vehicleCapacity" className="text-lg">
                  Vehicle Capacity
                </Label>
                <Input
                  id="vehicleCapacity"
                  placeholder="Vehicle Capacity"
                  className="mt-2"
                  type="number"
                  name="vehicleCapacity"
                  onChange={handleChange}
                  value={capacityData.vehicleCapacity}
                />
                {errors.vehicleCapacity && (
                  <span className="text-red-500 text-sm">
                    {errors.vehicleCapacity}
                  </span>
                )}
              </div>
            </div>
            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-lg"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
