import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../../components/ui/checkbox";
import ScheduleBadge from "./ScheduleBadge";

export type ScheduleListItems = {
  vehicle: string;
  vehicleId: string;
  driver: string;
  departureTime: string;
  route: string;
  occupiedSeats: string;
  emptySeats: number;
  schedules: Array<{
    status: string;
    scheduleId: string;
    time: string;
  }>;
};

export const createColumns = (): ColumnDef<ScheduleListItems>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected()
            ? true
            : table.getIsSomePageRowsSelected()
            ? "indeterminate"
            : false
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "vehicle",
    header: "Vehicle",
  },
  {
    accessorKey: "driver",
    header: "Driver",
  },
  {
    accessorKey: "departureTime",
    header: "Departure Time",
  },
  {
    accessorKey: "route",
    header: "Route",
  },
  {
    accessorKey: "occupiedSeats",
    header: "Occupied Seats",
  },
  {
    accessorKey: "emptySeats",
    header: "Empty Seats",
  },
  {
    accessorKey: "dispatchVehicle",
    header: "Vehicle Status",
    cell: ({ row }) => {
      const schedules = row.original.schedules;
      return (
        <div className="flex gap-2">
          {schedules.map((schedule) => (
            <ScheduleBadge
              key={schedule.scheduleId}
              schedule={schedule}
              vehicleId={row.original.vehicleId}
            />
          ))}
        </div>
      );
    },
  },
];
