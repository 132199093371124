import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  ParcelForms,
  ParcelFormsError,
} from "../utility_components/utility_functions/utilityFunctions";
import { usePostCompanyAddParcelMutation } from "../../api/busLineApiData";
import { usePostTicketDataMutation } from "../../api/busLineOfficeApi";
import { useDispatch } from "react-redux";
import { setLoading } from "../../slices/loader";
import { clearError } from "../../slices/errorSlice";
import { useOfficeUserAuth } from "../../api/officeAuth";
import {
  TicketData,
  TicketDataError,
} from "../utility_components/utility_functions/utilityFunctions";
import { useGetCompanyParcelsQuery } from "../../api/busLineApiData";
import { successFunctionDisplay } from "../../success/success";
import { errorHandler } from "../../error/index";
import VehicleInputDropdown from "../../custom_components/utility_components/VehicleInputDropdown";
import { store } from "../../store/busLineStore";
import { getCompanyFleetFromOffice } from "../../api/busLineOfficeApi";
import { validateKenyanPhoneNumber } from "../utility_components/utility_functions/utilityFunctions";
import { useGetOfficeRevenueAndDataQuery } from "../../api/busLineOfficeApi";
import { usePostAddParcelOfficeMutation } from "../../api/busLineOfficeApi";
import VehicleParcelInputDropdown from "../utility_components/VehicleParcelInputDropdown";
import { useGetOfficeParcelDataQuery } from "../../api/busLineOfficeApi";
import { setRefetchTicketsPage } from "../../slices/refetchSlice";
import SelectTimeToTravel from "./SelectTimeToTravel";
import RouteInputDropdown from "../utility_components/RouteDropDown";

export default function OfflineTicketDialogOffice({
  isOpen,
  onClose,
  type,
  setSeatDialog,
  selectedSeats,
}) {
  const { officeName, companyId, token, officeId } = useOfficeUserAuth();
  const { refetch } = useGetOfficeParcelDataQuery(officeId);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [hasVehicle, setHasVehicle] = useState<boolean>(false);
  const [postParcelData, { isLoading, error, isError }] =
    usePostAddParcelOfficeMutation();
  const [vehicleId, setChosenVehicleId] = useState<string>("");
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const formattedDate = formatDate(new Date(Date.now()));
  const [isDone, setIsDone] = useState<boolean>(false);
  const { refetch: officeRefetch } = useGetOfficeRevenueAndDataQuery({
    officeId,
    page: 1,
    formattedDate,
  });

  useEffect(() => {
    (async () => {
      if (isDone) {
        await officeRefetch();
        setIsDone(false);
      }
    })();
  }, [isDone, officeRefetch]);

  const [postParcel, { isLoading: addParcelLoading }] =
    usePostCompanyAddParcelMutation();
  var [chosenVehicle, setChosenVehicle] = useState<string>("");
  var [chosenRoute, setChosenRoute] = useState<string>("");
  var [chosenOffice, setChosenOffice] = useState<string>("");

  const [postTicket, { isLoading: addTicketLoading }] =
    usePostTicketDataMutation();

  const dispatch = useDispatch();
  const { refetch: refetchCompanyParcels } = useGetCompanyParcelsQuery(
    companyId ?? "",
    {
      skip: !token || !companyId,
    }
  );
  const [parcelFormData, setParcelData] = useState<ParcelForms>({
    senderName: "",
    from: "",
    to: "",
    charges: 0,
    senderPhoneNumber: "",
    recipientPhone: "",
    recipientName: "",
  });

  const [ticketFormData, setTicketData] = useState<TicketData>({
    passengerName: "",
    from: "",
    to: "",
    fare: "",
    vehicleRegistration: "",
    phoneNumber: "",
    officeName: "",
    selectedSeats: selectedSeats.current,
  });
  store.dispatch(getCompanyFleetFromOffice.initiate(companyId));
  const [errors, setErrors] = useState<ParcelFormsError>({});
  const [ticketErrors, setTicketErrors] = useState<TicketDataError>({});
  const [timeSelected, setTimeSelected] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "parcel") {
      const { name, value, type, checked } = e.target;
      setParcelData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else if (type === "ticket") {
      const { name, value, type, checked } = e.target;
      setTicketData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const validateParcelForm = () => {
    let newErrors: ParcelFormsError = {};
    if (!parcelFormData.charges) newErrors.charges = "Charges are required";
    if (!parcelFormData.from.trim())
      newErrors.from = "From destination is required";
    if (!parcelFormData.recipientName)
      newErrors.recipientName = "Recipient name is required";
    if (!parcelFormData.recipientPhone.trim())
      newErrors.recipientPhone = "Recipient phone is required";
    else if (!validateKenyanPhoneNumber(parcelFormData.recipientPhone))
      newErrors.recipientPhone = "Invalid Phone number. Use the 07.. format";
    if (!parcelFormData.senderName) newErrors.senderName = "Sender is required";
    if (!parcelFormData.senderPhoneNumber.trim())
      newErrors.senderPhoneNumber = "Sender phone is required";
    else if (!validateKenyanPhoneNumber(parcelFormData.senderPhoneNumber))
      newErrors.senderPhoneNumber = "Invalid Phone number. Use the 07.. format";
    if (!parcelFormData.to.trim()) newErrors.to = "To destination is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateTicketForm = () => {
    let newErrors: TicketDataError = {};

    if (!ticketFormData.passengerName.trim())
      newErrors.passengerName = "Passenger is required";
    // if (!ticketFormData.from.trim())
    //   newErrors.from = "From destination is required";
    // if (!ticketFormData.to.trim()) newErrors.to = "To destination is required";

    if (!ticketFormData.fare) newErrors.fare = "Fare is required";

    if (!validateKenyanPhoneNumber(ticketFormData.phoneNumber))
      newErrors.phoneNumber = "Phone Number is required or incorrect format";
    if (!selectedSeats || selectedSeats.length === 0) {
      newErrors.selectedSeats = "Seat cannot be empty";
    }

    setTicketErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (type === "parcel") {
      if (validateParcelForm()) {
        const result = await postParcelData({
          parcelDataOffice: parcelFormData,
          officeId,
          companyId,
          vehicleId,
        });
        if ("data" in result) {
          parcelFormData.charges = 0;
          parcelFormData.from = "";
          parcelFormData.recipientName = "";
          parcelFormData.recipientPhone = "";
          parcelFormData.senderName = "";
          parcelFormData.senderPhoneNumber = "";
          parcelFormData.to = "";
          setChosenVehicleId("");

          refetch();
          dispatch(setLoading(addParcelLoading));
          successFunctionDisplay("Parcel saved successfully");
          onClose();
        } else {
          onClose();
          return errorHandler(result.error);
        }
      }
    } else if (type === "ticket") {
      if (validateTicketForm()) {

        if (chosenVehicle !== null && chosenOffice !== null) {
          const result = await postTicket({
            ticketData: ticketFormData,
            vehicleRegistrationNumber: chosenVehicle,
            officeName: officeName,
            selectedSeats,
            chosenRoute,
          });

          if ("data" in result) {
            const pdfBlob = new Blob(
              [
                Uint8Array.from(atob(result.data.pdfBuffer), (c) =>
                  c.charCodeAt(0)
                ),
              ],
              { type: "application/pdf" }
            );
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, "_blank");
            ticketFormData.fare = "";
            ticketFormData.from = "";
            ticketFormData.phoneNumber = "";
            ticketFormData.passengerName = "";
            ticketFormData.phoneNumber = "";
            ticketFormData.to = "";
            ticketFormData.selectedSeats = [];
            selectedSeats.current= []
            setTimeSelected(null);
            setChosenRoute("");
            setChosenOffice("");
            setChosenVehicle("");
            dispatch(setLoading(addTicketLoading));
            successFunctionDisplay("Ticket saved successfully");
            onClose();
            setIsDone(true);
            dispatch(setRefetchTicketsPage({ refetchTicketsPage: true }));
          } else {
            onClose();
            return errorHandler(result.error);
          }
        }
      }
    }
  };
  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  return (
    <>
      {isDialogOpen && (
        <SelectTimeToTravel
          isOpen={isDialogOpen}
          setIsOpen={setDialogOpen}
          setTimeSelected={setTimeSelected}
        />
      )}
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="sm:max-w-[650px] w-full"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            {type === "ticket" ? (
              <Button
                onClick={() => setDialogOpen(true)}
                className="mt-4 bg-blue-500 mx-auto w-auto"
              >
                {timeSelected === null ? "Select Time to Travel" : timeSelected}
              </Button>
            ) : (
              <></>
            )}
            <DialogTitle className="text-2xl">
              Add {type === "ticket" ? "Passenger" : "Parcel"} Information
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            {type === "ticket" && (
              <div className="w-full p-6 space-y-5 bg-customLightBlue rounded-md">
                <div>
                  <Label htmlFor="passengerName" className="text-lg">
                    Passenger Name
                  </Label>
                  <Input
                    id="passengerName"
                    placeholder="Passenger Name"
                    className="mt-2"
                    name="passengerName"
                    onChange={handleChange}
                    value={ticketFormData.passengerName}
                  />
                  {ticketErrors.passengerName && (
                    <span className="text-red-500 text-sm">
                      {ticketErrors.passengerName}
                    </span>
                  )}
                </div>

                <div className="flex-1">
                  <Label htmlFor="vehcReg" className="text-lg block mb-2">
                    Choose Destination (From - To)
                  </Label>
                  <RouteInputDropdown
                    onValueChange={setChosenRoute}
                    placeHolder="Choose Destination (From - To)"
                    value={chosenRoute}
                  />
                </div>

                {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                  <div>
                    <Label htmlFor="from" className="text-lg">
                      From
                    </Label>
                    <Input
                      id="from"
                      placeholder="From"
                      className="mt-2"
                      name="from"
                      onChange={handleChange}
                      value={ticketFormData.from}
                    />
                    {ticketErrors.from && (
                      <span className="text-red-500 text-sm">
                        {ticketErrors.from}
                      </span>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="to" className="text-lg">
                      To
                    </Label>
                    <Input
                      id="to"
                      placeholder="To"
                      className="mt-2"
                      name="to"
                      onChange={handleChange}
                      value={ticketFormData.to}
                    />
                    {ticketErrors.to && (
                      <span className="text-red-500 text-sm">
                        {ticketErrors.to}
                      </span>
                    )}
                  </div>
                </div> */}
                <div>
                  <Label htmlFor="fare" className="text-lg">
                    Fare
                  </Label>
                  <Input
                    id="fare"
                    placeholder="Fare"
                    type="number"
                    className="mt-2"
                    name="fare"
                    onChange={handleChange}
                    value={ticketFormData.fare}
                  />
                  {ticketErrors.fare && (
                    <span className="text-red-500 text-sm">
                      {ticketErrors.fare}
                    </span>
                  )}
                </div>
                <div className="flex flex-col sm:flex-row gap-5 items-start sm:items-center">
                  <div className="flex-1">
                    <Label htmlFor="vehcReg" className="text-lg block mb-2">
                      Vehicle Registration
                    </Label>
                    <VehicleInputDropdown
                      placeHolder="Vehicle Registration Number"
                      value={chosenVehicle}
                      onValueChange={setChosenVehicle}
                      setHasVehicle={setHasVehicle}
                      time={timeSelected}
                    />
                  </div>

                  <div className="flex-1">
                    <Label htmlFor="seatNo" className="text-lg block mb-2">
                      Seat Number
                    </Label>
                    <Input
                      id="seatNo"
                      placeholder="Select Seat"
                      className="placeholder:text-blue-500 w-full h-[40px] text-center"
                      readOnly
                      onClick={() =>
                        hasVehicle && chosenVehicle && setSeatDialog(true)
                      }
                      value={
                        selectedSeats.length > 0
                          ? selectedSeats.join(", ")
                          : "Select Seat"
                      }
                    />
                    {ticketErrors.selectedSeats && (
                      <span className="text-red-500 text-sm">
                        {ticketErrors.selectedSeats}
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <Label htmlFor="phoneNumber" className="text-lg">
                    Phone Number
                  </Label>
                  <Input
                    id="phoneNumber"
                    placeholder="Phone Number"
                    className="mt-2"
                    type="number"
                    name="phoneNumber"
                    onChange={handleChange}
                    value={ticketFormData.phoneNumber}
                  />
                  {ticketErrors.phoneNumber && (
                    <span className="text-red-500 text-sm">
                      {ticketErrors.phoneNumber}
                    </span>
                  )}
                </div>
              </div>
            )}
            {type === "parcel" && (
              <div className="w-full p-6 space-y-5 bg-customLightBlue rounded-md">
                <div>
                  <Label htmlFor="senderName" className="text-lg">
                    Sender Name
                  </Label>
                  <Input
                    id="senderName"
                    placeholder="Sender Name"
                    className="mt-2"
                    name="senderName"
                    onChange={handleChange}
                    value={parcelFormData.senderName}
                  />
                  {errors.senderName && (
                    <span className="text-red-500 text-sm">
                      {errors.senderName}
                    </span>
                  )}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                  <div>
                    <Label htmlFor="from" className="text-lg">
                      From
                    </Label>
                    <Input
                      id="from"
                      placeholder="From"
                      className="mt-2"
                      name="from"
                      onChange={handleChange}
                      value={parcelFormData.from}
                    />
                    {errors.from && (
                      <span className="text-red-500 text-sm">
                        {errors.from}
                      </span>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="to" className="text-lg">
                      To
                    </Label>
                    <Input
                      id="to"
                      placeholder="To"
                      className="mt-2"
                      name="to"
                      onChange={handleChange}
                      value={parcelFormData.to}
                    />
                    {errors.to && (
                      <span className="text-red-500 text-sm">{errors.to}</span>
                    )}
                  </div>
                </div>
                <div>
                  <Label htmlFor="charges" className="text-lg">
                    Charges
                  </Label>
                  <Input
                    id="charges"
                    placeholder="charges"
                    type="number"
                    className="mt-2"
                    name="charges"
                    onChange={handleChange}
                    value={parcelFormData.charges}
                  />
                  {errors.charges && (
                    <span className="text-red-500 text-sm">
                      {errors.charges}
                    </span>
                  )}
                </div>
                <div>
                  <Label htmlFor="sendersNo" className="text-lg">
                    Senders Phone Number
                  </Label>
                  <Input
                    id="sendersNo"
                    placeholder="Sender's Phone Number"
                    className="mt-2"
                    name="senderPhoneNumber"
                    onChange={handleChange}
                    value={parcelFormData.senderPhoneNumber}
                  />
                  {errors.senderPhoneNumber && (
                    <span className="text-red-500 text-sm">
                      {errors.senderPhoneNumber}
                    </span>
                  )}
                </div>
                <div>
                  <Label htmlFor="recName" className="text-lg">
                    Recipient's Name
                  </Label>
                  <Input
                    id="recName"
                    placeholder="Recipient's Name"
                    className="mt-2"
                    name="recipientName"
                    onChange={handleChange}
                    value={parcelFormData.recipientName}
                  />
                  {errors.recipientName && (
                    <span className="text-red-500 text-sm">
                      {errors.recipientName}
                    </span>
                  )}
                </div>
                <div>
                  <Label htmlFor="recPhone" className="text-lg">
                    Recipient's Phone Number
                  </Label>
                  <Input
                    id="recPhone"
                    placeholder="Recipient's Phone Number"
                    type="number"
                    className="mt-2"
                    name="recipientPhone"
                    onChange={handleChange}
                    value={parcelFormData.recipientPhone}
                  />
                  {errors.recipientPhone && (
                    <span className="text-red-500 text-sm">
                      {errors.recipientPhone}
                    </span>
                  )}
                </div>
                <div>
                  <Label htmlFor="parcelVehicle" className="text-lg">
                    Vehicle for Parcel Transport
                  </Label>
                  <VehicleParcelInputDropdown
                    onValueChange={setChosenVehicleId}
                    value={vehicleId}
                    placeHolder="Select a vehicle"
                  />
                </div>
              </div>
            )}
            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-lg"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
