import React from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";

interface ExistingPasswordDisplayProps{
    isOpen: boolean;
    onClose: (value: boolean) => void;
    message:string
}

export default function ExistingPasswordDisplay({ isOpen, onClose, message }:ExistingPasswordDisplayProps) {
  return (
    <>
      <div className="bg-customLightBlue">
        <Dialog open={isOpen} onOpenChange={onClose}>
          <DialogContent
            className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
            onClick={(e) => e.stopPropagation()}
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                Office Password Info
              </DialogTitle>
            </DialogHeader>
            <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue py-10 px-5">
              <div>
                <strong>
                  <p className="text-xl">{message}</p>
                </strong>
              </div>
            </div>

            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-lg"
                onClick={() => onClose(false)}
              >
                Exit
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
