import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
export type CompanyRoutesAndFare = {
  routeName: string;
  startPoint: string;
  endPoint: string;
  fare: string;
  routeId: string;
};
interface RouteData {
  routeId: string;
  startRouteName: string;
  endRouteName: string;
  startPoint: string;
  endPoint: string;
}

type ColumnProps = {
  openFareDialog: (routeId: string) => void;
  openDeleteRouteDialog: (routeId: string) => void;
  onUpdateRouteDialog: (updateData: RouteData) => void;
};

export const createColumns = ({
  openFareDialog,
  openDeleteRouteDialog,
  onUpdateRouteDialog,
}: ColumnProps): ColumnDef<CompanyRoutesAndFare>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected()
            ? true
            : table.getIsSomePageRowsSelected()
            ? "indeterminate"
            : false
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "routeName",
    header: "ROUTENAME",
  },
  {
    accessorKey: "startPoint",
    header: "STARTPOINT",
  },
  {
    accessorKey: "endPoint",
    header: "ENDPOINT",
  },
  {
    accessorKey: "fare",
    header: "FARE",
    cell: ({ row }) => {
      const fare = parseFloat(row.getValue("fare"));
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "KES",
      }).format(fare);
      return <div className="font-medium">{formatted}</div>;
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const companyFareAndRoutes = row.original;
      const startRouteName = row.original.routeName.split(" to ")[0];
      const endRouteName = row.original.routeName.split(" to ")[1];
      const updateData = {
        routeId: companyFareAndRoutes.routeId,
        startRouteName: startRouteName,
        endRouteName: endRouteName,
        startPoint: companyFareAndRoutes.startPoint,
        endPoint: companyFareAndRoutes.endPoint,
      } as RouteData;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => onUpdateRouteDialog(updateData)}>
              Update Route Details
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => openFareDialog(companyFareAndRoutes.routeId)}
            >
              Add/Update Route Fare
            </DropdownMenuItem>
            <DropdownMenuItem
              className="bg-red-500 text-white hover:bg-red-600"
              onClick={() =>
                openDeleteRouteDialog(companyFareAndRoutes.routeId)
              }
            >
              Delete Route
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
