import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RoutesUpdateRefetchState {
  routeUpdateRefetchSlice: boolean | null;
}

const initialState: RoutesUpdateRefetchState = {
    routeUpdateRefetchSlice: null,
};

const routeUpdateRefetchSlice = createSlice({
  name: "routeUpdateRefetchSlice",
  initialState,
  reducers: {
    setRouteUpdateRefetchSlice: (state, action: PayloadAction<boolean>) => {
      state.routeUpdateRefetchSlice = action.payload;
    },
  },
});

export const { setRouteUpdateRefetchSlice } = routeUpdateRefetchSlice.actions;
export default routeUpdateRefetchSlice.reducer;

