import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  VehicleRoute,
  VehicleRouteError,
} from "../utility_components/utility_functions/utilityFunctions";
import { useDispatch } from "react-redux";
import { clearError } from "../../slices/errorSlice";
import { usePostCompanyAddRouteMutation } from "../../api/busLineApiData";
import { useAuth } from "../../api/getCredentials";
import { setLoading } from "../../slices/loader";
import { errorHandler } from "../../error/index";
import { successFunctionDisplay } from "../../success/success";
import { useGetCompanyRoutesAndFareQuery } from "../../api/busLineApiData";

export default function OfficeAndRoutes({ isOpen, onClose }) {
  const { companyId, token } = useAuth();
  const [postRoute, { isLoading: addRouteLoading }] =
    usePostCompanyAddRouteMutation();
  const [routeData, setRouteData] = useState<VehicleRoute>({
    // routeName: "",
    startRouteName: "",
    endRouteName: "",
    startPoint: "",
    endPoint: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<VehicleRouteError>({});
  const { refetch } = useGetCompanyRoutesAndFareQuery(
    { companyId, page: 1 },
    {
      skip: !token || !companyId,
    }
  );

  const validateForm = () => {
    let newErrors: VehicleRouteError = {};

    // if (!routeData.routeName.trim())
    //   newErrors.routeName = "Route name is required";
    if (!routeData.startRouteName.trim())
      newErrors.startRouteName = "Start Route name is required";
    if (!routeData.endRouteName.trim())
      newErrors.endRouteName = "End Route name is required";
    if (!routeData.startPoint.trim())
      newErrors.startPoint = "Route start point  is required";
    if (!routeData.endPoint.trim())
      newErrors.endPoint = "Route end point is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setRouteData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
    
      const result = await postRoute({
        routeData,
        companyId,
      });
      if ("data" in result) {
        // routeData.routeName = "";
        routeData.startRouteName = "";
        routeData.endRouteName = "";
        routeData.startPoint = "";
        routeData.endPoint = "";
        refetch();
        dispatch(setLoading(addRouteLoading));
        successFunctionDisplay("Route added successfully");
        onClose();
      } else {
        return errorHandler(result.error);
      }

      onClose();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);
  return (
    <div className="bg-customLightBlue">
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Add Route Information
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue">
              <div>
                <Label htmlFor="name" className="text-lg">
                  Route Name e.g Eldoret to Nairobi
                </Label>
                {/* <Input
                    id="name"
                    placeholder="Route Name"
                    className="mt-2"
                    name="routeName"
                    onChange={handleChange}
                  value={routeData.routeName}
                /> */}
                <div className="flex">
                  <div>
                    <Input
                      id="sRouteName"
                      placeholder="Starting Route Name"
                      className="mt-2"
                      name="startRouteName"
                      onChange={handleChange}
                      value={routeData.startRouteName}
                    />
                    {errors.startRouteName && (
                      <span className="text-red-500 text-sm">
                        {errors.startRouteName}
                      </span>
                    )}
                  </div>
                  <p className="px-3 py-3">to</p>
                  <div>
                    <Input
                      id="eRouteName"
                      placeholder="Ending Route Name"
                      className="mt-2"
                      name="endRouteName"
                      onChange={handleChange}
                      value={routeData.endRouteName}
                    />
                    {errors.endRouteName && (
                      <span className="text-red-500 text-sm">
                        {errors.endRouteName}
                      </span>
                    )}
                  </div>
                </div>

                {/* {errors.routeName && (
                  <span className="text-red-500 text-sm">
                    {errors.routeName}
                  </span>
                )} */}
              </div>
              <div>
                <Label htmlFor="startPoint" className="text-lg">
                  Start Point
                </Label>
                <Input
                  id="spoint"
                  placeholder="Start Point"
                  className="mt-2"
                  name="startPoint"
                  onChange={handleChange}
                  value={routeData.startPoint}
                />
                {errors.startPoint && (
                  <span className="text-red-500 text-sm">
                    {errors.startPoint}
                  </span>
                )}
              </div>
              <div>
                <Label htmlFor="endPoint" className="text-lg">
                  End Point
                </Label>
                <Input
                  id="endPoint"
                  placeholder="End Point"
                  className="mt-2"
                  name="endPoint"
                  onChange={handleChange}
                  value={routeData.endPoint}
                />
                {errors.endPoint && (
                  <span className="text-red-500 text-sm">
                    {errors.endPoint}
                  </span>
                )}
              </div>
            </div>
            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-lg"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
