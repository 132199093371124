import React, { useState } from "react";
import { Button } from "../../components/ui/button";
import OfficeAndRoutes from "./OfficeAndRoutes";
import FareAmount from "./FareAmount";
import CompanyRoutesAndFareTable from "./RoutesAndFareTable/route_fare_page";
import DeleteRouteDialog from "../Custom/DeleteRouteDialog";
import RouteUpdateDialog from "./RoutesUpdate";

interface RouteData {
  routeId: string;
  startRouteName: string;
  endRouteName: string;
  startPoint: string;
  endPoint: string;
}
export default function RoutesAndFare() {
  const [isRouteDialogOpen, setIsRouteDialogOpen] = useState(false);
  const [isFareDialogOpen, setIsFareDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRouteId, setSelectedRouteId] = useState<string | null>(null);
  const [selectedDeleteRouteId, setSelectedDeleteRouteId] = useState<
    string | null
  >(null);
  const [selectedUpdateData, setSelectedUpdateData] =
    useState<RouteData | null>(null);
    const [isUpdateDialogOpen, setUpDateDialogOpen] = useState(false);

  const openRouteDialog = () => setIsRouteDialogOpen(true);
  const closeRouteDialog = () => setIsRouteDialogOpen(false);

  const openFareDialog = (routeId: string) => {
    setSelectedRouteId(routeId);
    setIsFareDialogOpen(true);
  };

  const openDeleteRouteDialog = (routeId: string) => {
    setSelectedDeleteRouteId(routeId);
    setDeleteDialogOpen(true);
  };

  const openUpdateRouteDialog = (updateData:RouteData) => {
    setSelectedUpdateData(updateData);
    setUpDateDialogOpen(true);
  };

  const closeFareDialog = () => {
    setIsFareDialogOpen(false);
    setSelectedRouteId(null);
  };

  return (
    <>
      <Button
        className="bg-customDarkBlue text-white mx-auto mt-4 block"
        onClick={openRouteDialog}
      >
        Add Routes
      </Button>

      <CompanyRoutesAndFareTable
        openFareDialog={openFareDialog}
        openDeleteRouteDialog={openDeleteRouteDialog}
        onUpdateRouteDialog={openUpdateRouteDialog}
      />

      <OfficeAndRoutes isOpen={isRouteDialogOpen} onClose={closeRouteDialog} />

      {selectedRouteId && (
        <FareAmount
          isOpen={isFareDialogOpen}
          onClose={closeFareDialog}
          routeId={selectedRouteId}
        />
      )}

      {selectedDeleteRouteId && (
        <DeleteRouteDialog
          isOpen={isDeleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          routeId={selectedDeleteRouteId}
        />
      )}

      {selectedUpdateData &&<RouteUpdateDialog
        endPoint={selectedUpdateData.endPoint}
        endRouteName={selectedUpdateData.endRouteName}
        routeId={selectedUpdateData.routeId}
        startPoint={selectedUpdateData.startPoint}
        startRouteName={selectedUpdateData.startRouteName}
        isUpdateDialogOpen={isUpdateDialogOpen}
        setUpdateDialogOpen={setUpDateDialogOpen}
      />}
    </>
  );
}
