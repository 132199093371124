import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { BusFrontIcon } from "lucide-react";
import RouteSelector from "./RouteSelect";
import AvailableVehicles from "./AvailableVehicles";
import { useAddVehicleToScheduleMutation } from "../../api/busLineOfficeApi";
import { errorHandler } from "../../error/index";
import { successFunctionDisplay } from "../../success/success";
import { useDispatch } from "react-redux";
import { setRefetch } from "../../slices/addVehicleToSchedule";
import { timeStampToEastAfricanTime } from "../utility_components/utility_functions/utilityFunctions";


interface AddTimePickerProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  vehicleData: VehicleData[];
  selectedTime: string;
  routeNames: string[];
  selectedScheduleId: string;
}

interface VehicleData {
  id: string;
  registrationNumber: string;
  model: string;
  capacity: number;
}

const AddTimePickerDialog = ({
  isOpen,
  setOpen,
  vehicleData,
  selectedTime,
  routeNames,
  selectedScheduleId,
}: AddTimePickerProps) => {
  const dispatch = useDispatch();
  const [postVehicleScheduleData] = useAddVehicleToScheduleMutation();
  const [isVehicleSelectDialogOpen, setVehicleSelectDialogOpen] =
    useState<boolean>(false);

  const [isRouteSelectDialogOpen, setRouteSelectDialogOpen] =
    useState<boolean>(false);
  const [value, setValue] = useState<VehicleData[]>([]);

  const handleConfirm = async () => {
    setOpen(false);
    const postData = await postVehicleScheduleData({
      vehicleId,
      scheduleId: selectedScheduleId,
    });
    if ("data" in postData) {
      successFunctionDisplay("Vehicle successfully added to schedule");
      dispatch(setRefetch({ refetch: true }));
    } else {
      return errorHandler(postData.error);
    }
  };
  const vehicleId = value.map((vehicle) => vehicle.id);

  const handleChange = (value: VehicleData[]) => {
    setValue(value);
  };

  console.log(selectedScheduleId);

  const handleRouteChange = (value) => {
    console.log(value);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-green-900">New Schedule</DialogTitle>
          </DialogHeader>
          <div className="py-2">
            <h1 className="text-green-900 text-xl font-bold">{timeStampToEastAfricanTime(selectedTime)}</h1>
          </div>
          <p className="text-green-900 text-xl font-bold">
            {" "}
            {routeNames.map((r) => r)}
          </p>
          <div className="flex space-x-6 items-center">
            <div
              className="flex flex-col items-center"
              onClick={() => setVehicleSelectDialogOpen(true)}
            >
              <h1 className="text-green-900 text-xl">{ `${value?.map((vehicle) => vehicle.registrationNumber,)} `}</h1>
              <div className="w-20 h-20 bg-green-900 flex items-center justify-center rounded-md">
                <BusFrontIcon size={32} color="white" />
              </div>
              <p className="mt-2 text-green-900 font-semibold">Add Vehicle</p>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button
              onClick={handleConfirm}
              className="bg-green-900"
              // variant="outline"
            >
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {isVehicleSelectDialogOpen && (
        <AvailableVehicles
          onSelectionChange={handleChange}
          isDialogOpen={isVehicleSelectDialogOpen}
          setDialogOpen={setVehicleSelectDialogOpen}
          vehicleData={vehicleData}
        />
      )}

      {isRouteSelectDialogOpen && (
        <RouteSelector
          isDialogOpen={isRouteSelectDialogOpen}
          setDialogOpen={setRouteSelectDialogOpen}
          onSelectionChange={handleRouteChange}
        />
      )}
    </>
  );
};

export { AddTimePickerDialog };
