import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RefetchSlice {
  refetchTicketsPage: boolean | null;
}

const initialState: RefetchSlice = {
  refetchTicketsPage: null,
};

const variousSlices = createSlice({
  name: "variousSlices",
  initialState,
  reducers: {
    setRefetchTicketsPage: (state, action: PayloadAction<RefetchSlice>) => {
      state.refetchTicketsPage = action.payload.refetchTicketsPage;
    },
  },
});

export const { setRefetchTicketsPage } = variousSlices.actions;
export default variousSlices.reducer;
