import React, { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";

import OwnerAndVehicle from "./OwnerAndVehicle";

import CompanyFleetTableData from "./CompanyFleet Table/company_fleet_page";
import VehicleDetailsDialog from "./ViewVehicleDetails";
import DeleteVehicleDialog from "../Custom/DeleteVehicleDialog";
import VehicleUpdateDialog from "../Custom/VehicleUpdateDialog";
import { useLazyGetSpecificVehicleDetailsQuery } from "../../../src/api/busLineApiData";
import { errorHandler } from "../../error/index";

interface VehicleDetails {
  collectiveData: {
    model: string;
    registrationNumber: string;
    capacity: number;
    owner: {
      email: string;
      name: string;
      phone: string;
    };
    company: {
      name: string;
    };
    driver: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
    };
  };
}

const FleetOwners: React.FC = () => {
  const [getVehicleData, { data, error }] =
    useLazyGetSpecificVehicleDetailsQuery();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isViewVehicleDetailsOpen, setViewVehicleDetailsOpen] =
    useState<boolean>(false);
  const [isDeleteVehicleDialogOpen, setDeleteVehicleDialog] =
    useState<boolean>(false);
  const [vehicleDeleteId, setVehicleDeleteId] = useState<string>();
  const [vehicleId, setVehicleId] = useState<string>();
  const [isVehicleUpdateOpen, setVehicleUpdateOpen] = useState<boolean>(false);
  const [updateVehicleId, setUpdateVehicleId] = useState<string>();
  const [vehicleData, setVehicleData] = useState<VehicleDetails>();
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const onGetVehicleId = (vehicleId: string) => {
    setVehicleId(vehicleId);
    setViewVehicleDetailsOpen(true);
  };

  const onGetDeleteVehicleId = (vehicleId: string) => {
    setVehicleDeleteId(vehicleId);
    setDeleteVehicleDialog(true);
  };

  const onGetUpdateVehicleId = async (vehicleId: string) => {
    setUpdateVehicleId(vehicleId);
    setVehicleUpdateOpen(true);
    await getVehicleData({ vehicleId });
  };


  useEffect(() => {
    if (data) {
      setVehicleData(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <>
      <Button
        className="bg-customDarkBlue text-white mx-auto mt-4 block"
        onClick={openDialog}
      >
        Add Owner & vehicle
      </Button>
      <CompanyFleetTableData
        onGetVehicleId={onGetVehicleId}
        onGetVehicleDeleteId={onGetDeleteVehicleId}
        onGetUpdateVehicleId={onGetUpdateVehicleId}
      />
      <OwnerAndVehicle isOpen={isDialogOpen} onClose={closeDialog} />
      {vehicleId && (
        <VehicleDetailsDialog
          isOpen={isViewVehicleDetailsOpen}
          onClose={setViewVehicleDetailsOpen}
          vehicleId={vehicleId}
        />
      )}
      {vehicleDeleteId && (
        <DeleteVehicleDialog
          vehicleId={vehicleDeleteId}
          isOpen={isDeleteVehicleDialogOpen}
          setOpen={setDeleteVehicleDialog}
        />
      )}

      {updateVehicleId && vehicleData && (
        <VehicleUpdateDialog
          isOpen={isVehicleUpdateOpen}
          onClose={setVehicleUpdateOpen}
          vehicleDetails={vehicleData}
          vehicleId={updateVehicleId}
        />
      )}
    </>
  );
};
export default FleetOwners;
