import React from "react";
import { Card, CardContent } from "../components/ui/card";
import { Mail, Phone } from "lucide-react";

export default function ContactUs() {
  return (
    <div className="min-h-screen flex items-center justify-center p-4 bg-customLightBlue">
      <Card className="w-full max-w-md bg-blue-500/10 backdrop-blur-sm border-blue-200/30 border shadow-lg">
        <CardContent className="p-6">
          <div className="space-y-4">
            <h2 className="text-center text-xl font-bold  mb-4">
              Contact Information
            </h2>
            
            <div className="flex items-center space-x-4">
              <Mail className=" w-6 h-6" />
              <span className="text-base sm:text-xl font-bold">
                buslinedesk@gmail.com
              </span>
            </div>
            
            <div className="flex items-center space-x-4">
              <Phone className=" w-6 h-6" />
              <span className="text-base sm:text-lg ">
                +254 115 221 157
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}