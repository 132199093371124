import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { useLazyUpdateOfficeDataQuery } from "../../../src/api/busLineApiData";
import { errorHandler } from "../../error/index";
import { useAuth } from "../../api/getCredentials";
import { successFunctionDisplay } from "../../success/success";
import { useDispatch } from "react-redux";
import { setOfficeUpdateRefetchSlice } from "../../slices/officeUpdate";

interface Office {
  id: string;
  name: string;
  address: string;
  city: string;
}

interface UpdateBookingOfficeProps {
  isOpen: boolean;
  onClose: () => void;
  office: Office | null;
}

const UpdateBookingOffice: React.FC<UpdateBookingOfficeProps> = ({
  isOpen,
  onClose,
  office,
}) => {
  const dispatch = useDispatch();
  const { companyId } = useAuth();
  const [updateData] = useLazyUpdateOfficeDataQuery();
  const [officeData, setOfficeData] = useState<Office>({
    id: "",
    name: "",
    address: "",
    city: "",
  });

  const [errors, setErrors] = useState<Partial<Office>>({});

  useEffect(() => {
    if (office) {
      setOfficeData(office);
    }
  }, [office]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOfficeData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const newErrors: Partial<Office> = {};
    if (!officeData.name.trim()) newErrors.name = "Office name is required";
    if (!officeData.city.trim()) newErrors.city = "Office city is required";
    if (!officeData.address.trim())
      newErrors.address = "Office address is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const result = await updateData({
        companyId,
        officeId: officeData.id,
        officeName: officeData.name,
        officeAddress: officeData.address,
        officeCity: officeData.city,
      });
      if ("data" in result) {
        successFunctionDisplay("Office updated..");
        dispatch(setOfficeUpdateRefetchSlice(true));
      } else {
        return errorHandler(result.error);
      }
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-customLightBlue p-6 rounded-lg max-w-lg mx-auto">
        <DialogHeader className="text-center flex flex-col items-center justify-center">
          <DialogTitle className="text-lg font-medium text-gray-800">
            Update Booking Office
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="flex flex-col flex-grow">
          <div className="flex-grow p-4 space-y-4">
            <div>
              <Label htmlFor="name">Office Name</Label>
              <Input
                id="name"
                name="name"
                value={officeData.name}
                onChange={handleChange}
                placeholder="Office Name"
              />
              {errors.name && (
                <span className="text-red-500 text-sm">{errors.name}</span>
              )}
            </div>
            <div>
              <Label htmlFor="address">Office Address</Label>
              <Input
                id="address"
                name="address"
                value={officeData.address}
                onChange={handleChange}
                placeholder="Office Address"
              />
              {errors.address && (
                <span className="text-red-500 text-xs">{errors.address}</span>
              )}
            </div>
            <div>
              <Label htmlFor="city">City</Label>
              <Input
                id="city"
                name="city"
                value={officeData.city}
                onChange={handleChange}
                placeholder="Office City"
              />
              {errors.city && (
                <span className="text-red-500 text-xs">{errors.city}</span>
              )}
            </div>
          </div>
          <DialogFooter className="flex justify-center mt-4">
            <Button
              type="submit"
              className="bg-blue-600 text-white hover:bg-blue-700"
            >
              Update
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateBookingOffice;
