import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AddVehicleRefetch {
  refetch: boolean | null;
}

const initialState: AddVehicleRefetch = {
    refetch: null,
};

const refetch = createSlice({
  name: "refetch",
  initialState,
  reducers: {
    setRefetch: (state, action: PayloadAction<AddVehicleRefetch>) => {
      state.refetch = action.payload.refetch;
    },
  },
});

export const { setRefetch } = refetch.actions;
export default refetch.reducer;
