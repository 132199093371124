import React, { useRef, useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import DynamicSeatComponent from "../Custom/Seats";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../slices/types";
import { Loader2 } from "lucide-react";
import { useGetSeatVehicleDataQuery } from "../../api/busLineOfficeApi";
import { errorHandler } from "../../error/index";

interface SetDialogProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  onSeatChange: (value: string[]) => void;
}

export default function SeatDialog({
  isOpen,
  onClose,
  onSeatChange,
}: SetDialogProps) {
  const selectedSeatsRef = useRef<string[]>([]);
  const [selectedSeats, setSelectedSeats] = useState<string[]>([]);
  const [, setForceUpdate] = useState(false);
  const dispatch = useDispatch();
  const { refetchTicketsPage } = useSelector(
    (state: RootState) => state.refetchTicketsPage
  );

  const updateSelectedSeats = (updatedSeats: string[]) => {
    selectedSeatsRef.current = updatedSeats;
    setForceUpdate((prev) => !prev);
    onSeatChange(selectedSeatsRef.current);
    setSelectedSeats(updatedSeats);
    console.log("Updated selected seats:", updatedSeats);
  };
  const { vehicleId } = useSelector((state: RootState) => state.vehicleId);
  const { isLoading, data, isError, error, refetch } =
    useGetSeatVehicleDataQuery(vehicleId, { skip: !vehicleId });
  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);
  useEffect(() => {
    (async () => {
      if (!refetchTicketsPage) {
        await refetch();
      }
    })();
  }, [dispatch, refetch, refetchTicketsPage]);
  if (!isOpen) return null;
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center pointer-events-none">
      <div className="pointer-events-auto">
        <Dialog open={isOpen} onOpenChange={onClose}>
          <DialogContent
            className="w-full max-w-xs flex flex-col max-h-[90vh] bg-customLightBlue rounded-lg p-4 shadow-md"
            onClick={(e) => e.stopPropagation()}
          >
            <DialogHeader>
              <DialogTitle className="text-xl mb-2">
                <div className="flex justify-center">
                  <Button
                    onClick={() => onClose(false)}
                    className="bg-blue-500"
                  >
                    Select Seat(s)
                  </Button>
                </div>
              </DialogTitle>
            </DialogHeader>
            <DynamicSeatComponent
              capacity={data?.capacity || 0}
              selectedSeatsRef={selectedSeats} // Pass user-selected seats
              bookedSeats={data?.bookedSeats || []} // Pass the booked seats from DB
              onSeatChange={updateSelectedSeats}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
