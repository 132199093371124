import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AddTimeRefetch {
  updateVehicleDataRefetch: boolean | null;
}

const initialState: AddTimeRefetch = {
    updateVehicleDataRefetch: null,
};

const addTimeRefetch = createSlice({
  name: "addTimeRefetch",
  initialState,
  reducers: {
    setUpdateVehicleData: (state, action: PayloadAction<AddTimeRefetch>) => {
      state.updateVehicleDataRefetch = action.payload.updateVehicleDataRefetch;
    },
  },
});

export const { setUpdateVehicleData } = addTimeRefetch.actions;
export default addTimeRefetch.reducer;
