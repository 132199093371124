import React, { useEffect, useState } from "react";
import {
  CompanyReportTicketsData,
  columns,
} from "./company_tickets_reports_column";
import { DataTable } from "../Re-Usables/data-table";
import { useAuth } from "../../../api/getCredentials";
import { useGetCompanyFilteredTicketSalesQuery } from "../../../api/busLineApiData";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";
import { Button } from "../../../components/ui/button";

export default function CompanyTicketsReportTable() {
  const { companyId } = useAuth();
  const [state, setState] = React.useState({
    pageIndex: 0,
    period: "today",
    previousPeriod: "today", // Track previous period for comparison
  });

  const credentials = {
    companyId,
    period: state.period,
    page: state.pageIndex + 1,
  };

  const {
    isLoading,
    error,
    isError,
    data: ticketData,
    refetch,
  } = useGetCompanyFilteredTicketSalesQuery({ credentials });

  // Handle period change
  const handlePeriodChange = (newPeriod: string) => {
    setState(prev => ({
      ...prev,
      pageIndex: 0, // Reset to first page when changing period
      period: newPeriod,
      previousPeriod: prev.period,
    }));
  };

  // Handle pagination change
  const handlePaginationChange = (newPageIndex: number) => {
    setState(prev => ({
      ...prev,
      pageIndex: newPageIndex,
    }));
  };

  // Effect to refetch data when period changes
  useEffect(() => {
    if (state.period !== state.previousPeriod) {
      refetch();
    }
  }, [state.period, state.previousPeriod, refetch]);

  // Handle errors
  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  const prepareTableData = (): CompanyReportTicketsData[] => {
    if (
      !ticketData?.companyData?.offices
    ) {
      return [];
    }

    return ticketData.companyData.offices.flatMap((office) =>
      office.tickets.map((ticket) => ({
        ticketNumber: ticket.ticketNumber,
        userName: `${ticket.user?.firstName ?? ""} ${
          ticket.user?.lastName ?? ""
        }`,
        ticketId: ticket.id,
        userPhoneNumber: ticket.user?.phoneNumber ?? "N/A",
        from: ticket.route?.startPoint ?? "N/A",
        to: ticket.route?.endPoint ?? "N/A",
        price: ticket.price,
      }))
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();

  const periodButtons = [
    { label: "Today", value: "today" },
    { label: "This Week", value: "week" },
    { label: "This Month", value: "month" },
    { label: "This Year", value: "year" },
  ];

  return (
    <div className="container mx-auto py-10">
      <div className="mb-4 flex flex-wrap gap-2">
        {periodButtons.map((btn) => (
          <Button
            key={btn.value}
            onClick={() => handlePeriodChange(btn.value)}
            variant="outline"
            className={`px-4 py-2 text-sm ${
              state.period === btn.value
                ? "bg-customDarkBlue text-primary-foreground"
                : ""
            }`}
          >
            {btn.label}
          </Button>
        ))}
      </div>
      
      <div className="bg-[#7485BF] text-white text-center p-4 text-xl sm:text-2xl md:text-3xl font-semibold mb-6">
        {ticketData?.totalSells ? (
          <>Total Page Sales: Ksh {ticketData.totalSells.toFixed(2)}</>
        ) : (
          <>Total Page Sales: Ksh 0.00</>
        )}
      </div>

      <DataTable
        columns={columns}
        data={tableData}
        pageCount={ticketData?.totalPages ?? 1}
        pageIndex={state.pageIndex}
        onPaginationChange={handlePaginationChange}
      />
    </div>
  );
}
