import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VehicleUpdateRefetchState {
  vehicleUpdateRefetchSlice: boolean | null;
}

const initialState: VehicleUpdateRefetchState = {
    vehicleUpdateRefetchSlice: null,
};

const vehicleUpdateRefetchSlice = createSlice({
  name: "vehicleUpdateRefetchSlice",
  initialState,
  reducers: {
    setVehicleUpdateRefetchSlice: (state, action: PayloadAction<boolean>) => {
      state.vehicleUpdateRefetchSlice = action.payload;
    },
  },
});

export const { setVehicleUpdateRefetchSlice } = vehicleUpdateRefetchSlice.actions;
export default vehicleUpdateRefetchSlice.reducer;

