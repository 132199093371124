import React from "react";
import { Construction} from "lucide-react";

export default function TripsView() {
  return (
    <div className="flex flex-col justify-center items-center h-screen text-center">
    <Construction size={200} color="red" />
    <h1 className="text-3xl">Oops; Work in progress !</h1>
  </div>
  );
}
