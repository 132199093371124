import React, { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { errorHandler } from "../../error/index";
import { useOfficeUserAuth } from "../../api/officeAuth";
import { useGetBookingRouteQuery } from "../../api/busLineOfficeApi";

interface Route {
  id: string;
  name: string;
}

interface RouteInputDropdownProps {
  value: string;
  onValueChange: (value: string) => void;
  placeHolder: string;
}

export default function RouteInputDropdown({
  value,
  onValueChange,
  placeHolder,
}: RouteInputDropdownProps) {
  const { companyId } = useOfficeUserAuth();
  const [routes, setRoutes] = useState<Route[]>([]);

  const {
    isError,
    error,
    data: routeData,
  } = useGetBookingRouteQuery({ companyId }, { skip: !companyId });

  useEffect(() => {
    if (routeData?.companyRoute?.routes) {
      setRoutes(routeData.companyRoute.routes);
    }
  }, [routeData]);

  useEffect(() => {
    if (isError && error) {
      errorHandler(error);
    }
  }, [error, isError]);

  const handleRouteChange = (selectedValue: string) => {
    if (!selectedValue) return;
    onValueChange(selectedValue);
  };

  return (
    <Select value={value} onValueChange={handleRouteChange}>
      <SelectTrigger>
        <SelectValue placeholder={placeHolder} />
      </SelectTrigger>
      <SelectContent className="max-h-60 overflow-y-auto">
        {routes.length > 0 ? (
          routes.map((sRoute) => (
            <SelectItem key={sRoute.id} value={sRoute.name}>
              {sRoute.name}
            </SelectItem>
          ))
        ) : (
          <SelectItem value="none">No routes available</SelectItem>
        )}
      </SelectContent>
    </Select>
  );
}
