import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../../components/ui/checkbox";
import { ArrowUpDown, ArrowUp, ArrowDown } from "lucide-react";

export type VehiclesPerSchedule = {
  vehicle: string;
  contacts: string;
};
export const createColumns = (
  onReorder?: (fromIndex: number, toIndex: number) => void
): ColumnDef<VehiclesPerSchedule>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected()
            ? true
            : table.getIsSomePageRowsSelected()
            ? "indeterminate"
            : false
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "order",
    header: "ORDER",
    cell: ({ row }) => {
      const orderNumber = row.index + 1;
      return (
        <div className="font-medium">{orderNumber}</div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "vehicle",
    header: ({ column }) => {
      return (
        <div className="text-left">
          <button
            className="flex items-center gap-1"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            VEHICLE
            <ArrowUpDown className="h-4 w-4" />
          </button>
        </div>
      );
    },
  },
  {
    accessorKey: "contacts",
    header: "CONTACTS",
  },
  {
    id: "arrange",
    header: "ARRANGE",
    cell: ({ row, table }) => {
      const rowIndex = row.index;
      const totalRows = table.getRowModel().rows.length;

      const moveRow = (from: number, to: number) => {
        if (onReorder) {
          onReorder(from, to);
        }
      };

      return (
        <div className="flex gap-2">
          <button 
            className="p-1 hover:bg-gray-100 rounded disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => moveRow(rowIndex, rowIndex - 1)}
            disabled={rowIndex === 0}
            title={rowIndex === 0 ? "Already at top" : "Move up"}
          >
            <ArrowUp className="h-4 w-4" />
          </button>
          <button 
            className="p-1 hover:bg-gray-100 rounded disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => moveRow(rowIndex, rowIndex + 1)}
            disabled={rowIndex === totalRows - 1}
            title={rowIndex === totalRows - 1 ? "Already at bottom" : "Move down"}
          >
            <ArrowDown className="h-4 w-4" />
          </button>
        </div>
      );
    },
  },
];