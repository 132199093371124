import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
export type CompanyFleetData = {
  vehicle: string;
  owner: string;
  phoneNumber: string;
  model: string;
  status: string;
  vehicleId: string;
  onGetVehicleId: (vehicleId: string) => void;
  onGetVehicleDeleteId: (vehicleId: string) => void;
  onGetUpdateVehicleId:(vehicleId: string) => void;
};

export const columns = (
  onGetVehicleId: (vehicleId: string) => void,
  onGetVehicleDeleteId: (vehicleId: string) => void,
  onGetUpdateVehicleId:(vehicleId: string) => void
): ColumnDef<CompanyFleetData>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <input
        type="checkbox"
        checked={table.getIsAllPageRowsSelected()}
        onChange={() => table.toggleAllPageRowsSelected()}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={row.getIsSelected()}
        onChange={() => row.toggleSelected()}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "vehicle",
    header: "VEHICLE",
  },
  {
    accessorKey: "owner",
    header: "VEHICLE OWNER",
  },
  {
    accessorKey: "phoneNumber",
    header: "PHONE NUMBER",
  },
  {
    accessorKey: "model",
    header: "MODEL",
  },
  {
    accessorKey: "status",
    header: "STATUS",
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const companyFleets = row.original;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => onGetVehicleId(companyFleets.vehicleId)}
            >
              View Vehicle Details
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                onGetUpdateVehicleId(companyFleets.vehicleId)
              }
            >
              Update Vehicle Data
            </DropdownMenuItem>
            <DropdownMenuItem
              className="bg-red-500 text-white"
              onClick={() => onGetVehicleDeleteId(companyFleets.vehicleId)}
            >
              Delete Vehicle
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
