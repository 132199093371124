import React, { useState,useRef } from "react";
import { useLocation } from "react-router-dom";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import TripsView from "../business_components/TripsView";
import ParcelViewOffice from "../../custom_components/Custom/ParcelViewOffice";
import OfflineTicketDialogOffice from "./OfflineOfficeTicketsParcels";

import OfficeTicketsTable from "../utility_components/OfficeTicketTable/office_tickets_page";
import SeatDialog from "./SeatDialog";

export default function OfficeActions() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "bg-blue-600 text-white" : "";
  };
  const [currentView, setCurrentView] = useState("ticket");
  const [dialogState, setDialogState] = useState({ isOpen: false, type: null });
  const [isSeatDialogOpen, setSeatDialog] = useState<boolean>(false);

  const openDialog = (type) => setDialogState({ isOpen: true, type });
  const closeDialog = () => setDialogState({ isOpen: false, type: null });
  const selectedSeats = useRef<string[]>([]);
  const [, setForceUpdate] = useState<boolean>(false);

  const handleUpdatedSeats = (updatedSeats: string[]) => {
    selectedSeats.current = updatedSeats;
    setForceUpdate((prev) => !prev);    
  }
  

  return (
    <div className="flex min-h-screen w-full bg-background ">
      <main className="flex-1 px-4 py-6 sm:px-6">
        <div className="flex items-center gap-4">
          <div className="relative flex-1">
            <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search..."
              className="w-full rounded-lg bg-background pl-8"
            />
          </div>
          <div className="flex gap-2">
            <Button
              variant="outline"
              className={`px-4 py-2 text-sm ${
                currentView === "ticket"
                  ? "bg-customDarkBlue text-primary-foreground"
                  : ""
              }`}
              onClick={() => setCurrentView("ticket")}
            >
              Ticket
            </Button>
            <Button
              variant="outline"
                className={`px-4 py-2 text-sm ${
                  currentView === "parcel"
                    ? "bg-customDarkBlue text-primary-foreground"
                    : ""
                }`}
              onClick={() => setCurrentView("parcel")}
            >
              Parcel
            </Button>
            <Button
              variant="outline"
              className={`px-4 py-2 text-sm ${
                currentView === "trips"
                  ? "bg-customDarkBlue text-primary-foreground"
                  : ""
              }`}
              onClick={() => setCurrentView("trips")}
            >
              Trips & Shares
            </Button>
          </div>
        </div>
        {isSeatDialogOpen && (
          <SeatDialog
            isOpen={isSeatDialogOpen}
            onClose={() => setSeatDialog(false)}
            onSeatChange={handleUpdatedSeats}
          />
        )}
        <OfflineTicketDialogOffice
          isOpen={dialogState.isOpen}
          onClose={closeDialog}
          type={dialogState.type}
          setSeatDialog={() => setSeatDialog(true)}
          selectedSeats={selectedSeats.current}
        />
        {currentView === "ticket" && (
          <OfficeTicketsTable openDialog={openDialog} />
        )}
        {currentView === "parcel" && (
          <ParcelViewOffice openDialog={openDialog} />
        )}
        {currentView === "trips" && <TripsView />}
      </main>
    </div>
  );
}

function SearchIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  );
}
