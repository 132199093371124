import React from "react";
import { ColumnDef } from "@tanstack/react-table";

import { Checkbox } from "../../../components/ui/checkbox";

import { formatTimestamp } from "../../utility_components/utility_functions/utilityFunctions";

export type IndividualOfficeSharesData = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  registrationNumber: string;
  sharesPaid: Number;
  dateSharesPaid: string;
};

export const columns: ColumnDef<IndividualOfficeSharesData>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected()
            ? true
            : table.getIsSomePageRowsSelected()
            ? "indeterminate"
            : false
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
  },
  {
    accessorKey: "phoneNumber",
    header: "Driver Phone Number",
  },
  {
    accessorKey: "registrationNumber",
    header: "Vehicle Reg No",
  },
  {
    accessorKey: "sharesPaid",
    header: "Shares Paid",
  },

  {
    accessorKey: "dateSharesPaid",
    header: "Date Shares Paid",
    cell: ({ row }) => {
      const date: string = row.getValue("dateSharesPaid");
      const formatted = formatTimestamp(date);
      return <div className="font-medium">{formatted}</div>;
    },
  },
];
