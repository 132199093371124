import React from "react";

interface TeamData {
  name: string;
  image: string;
}

const teamMembers: TeamData[] = [
  {
    name: "Victor Chelule",
    image: "/vic.png",
  },
  {
    name: "Raymond Kiprop",
    image: "/ray.png",
  },
  {
    name: "Keith Koki",
    image: "/kok.png",
  },
  {
    name: "Christopher Jesse",
    image: "/cj.png",
  },
  {
    name: "Jerome Juma",
    image: "/jer.png",
  },
];

export default function AboutUs() {
  return (
    <div className="min-h-screen flex flex-col justify-center relative px-4 py-12 bg-customLightBlue">
      <div className="absolute top-8 left-8 z-10">
        <h1 className="text-6xl lg:text-8xl font-bold text-blue-500/20 tracking-widest">
          TEAM
        </h1>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-8 md:gap-12 lg:gap-16">
        {teamMembers.map((member, index) => (
          <div key={index} className="flex flex-col items-center space-y-4">
            <div
              className="w-40 h-40 md:w-48 md:h-48 lg:w-56 lg:h-56 
              rounded-full overflow-hidden border-4 border-blue-500/20 
              shadow-lg hover:scale-105 transition-transform duration-300"
            >
              <img
                src={`${member.image}`}
                alt={member.name}
                className="w-full h-full object-cover"
              />
            </div>
            <h3 className="text-amber-400 font-semibold text-base md:text-lg lg:text-xl">
              {member.name}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
}
