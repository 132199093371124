import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DriverRefetchSlice {
 driverRefetch: boolean | null;
}

const initialState: DriverRefetchSlice = {
  driverRefetch: null,
};

const driverRefetch = createSlice({
  name: "driverRefetch",
  initialState,
  reducers: {
    setDriverRefetch: (state, action: PayloadAction<DriverRefetchSlice>) => {
      state.driverRefetch = action.payload.driverRefetch;
    },
  },
});

export const { setDriverRefetch } = driverRefetch.actions;
export default driverRefetch.reducer;
