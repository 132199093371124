import React, { useEffect, useState, useCallback } from "react";
import { createColumns, ScheduleListItems } from "./schedule_list_column";
import { DataTable } from "../Re-Usables/data-table";
import { useGetScheduleListQuery } from "../../../api/busLineOfficeApi";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";
import { useOfficeUserAuth } from "../../../api/officeAuth";
import { timeStampToEastAfricanTime } from "../../utility_components/utility_functions/utilityFunctions";
import { DatePickerDemo } from "../../../components/ui/DatePickerDemo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slices/types";
import { setRefetchSlice } from "../../../slices/scheduleRefetch";

interface TableData {
  time: string;
  vehicles: Vehicle[];
  route: Route[];
}

interface Schedule {
  status: string;
  scheduleId: string;
  schedule: {
    time: string;
  };
}

interface Vehicle {
  vehicle: {
    id: string;
    bookedSeats: string[];
    registrationNumber: string;
    schedules: Schedule[];
    capacity:number
  };
  driver: Driver;
}
interface Driver {
  firstName: string;
  lastName: string;
}
interface Route {
  name: string;
}

export default function ScheduleListPage() {
  const dispatch = useDispatch();
  const scheduleRefetch = useSelector((state: RootState) => state.scheduleRefetch.scheduleRefetchSlice);
  const { officeId } = useOfficeUserAuth();
  const [pageIndex, setPageIndex] = useState(0);
  const [date, setDate] = React.useState<Date | undefined>();
  const formattedDate = date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`
    : "";
  const handleDateChange = useCallback((newDate: Date | undefined) => {
    setDate(newDate);
  }, []);
  const { isLoading, data, error, isError, refetch } = useGetScheduleListQuery({
    officeId,
    date: formattedDate,
  });
  useEffect(() => {
    (async () => {
      if (scheduleRefetch) {
        await refetch();
      }
      dispatch(setRefetchSlice(false));
    })();
  },[dispatch, refetch, scheduleRefetch]);

  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  useEffect(() => {
    if (formattedDate) {
      refetch();
    }
  }, [formattedDate, refetch]);

  const prepareTableData = (): ScheduleListItems[] => {
    if (!data || !data.data) {
      return [];
    }

    return data.data.flatMap((scheduleItem: TableData) =>
      scheduleItem?.vehicles?.map((vehicle: Vehicle) => ({
        vehicle: vehicle?.vehicle?.registrationNumber,
        vehicleId: vehicle?.vehicle?.id,
        driver: `${vehicle?.driver?.firstName} ${vehicle?.driver?.lastName}`,
        departureTime: timeStampToEastAfricanTime(scheduleItem?.time),
        route: scheduleItem?.route[0]?.name || "No route specified.",
        emptySeats:((vehicle.vehicle.capacity)-(vehicle?.vehicle.bookedSeats?.length)),
        occupiedSeats:
          vehicle?.vehicle?.bookedSeats?.length === 0
            ? "None booked."
            : vehicle?.vehicle.bookedSeats?.length,
        schedules: vehicle?.vehicle?.schedules.map((schedule) => ({
          status: schedule.status,
          scheduleId: schedule.scheduleId,
          time: schedule.schedule.time,
        })),
      }))
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();

  return (
    <>
      <div className="flex justify-start mb-2">
        <DatePickerDemo
          date={date}
          placeHolder={null}
          setDate={handleDateChange}
        />
      </div>
      <DataTable
        columns={createColumns()}
        data={tableData}
        pageCount={1}
        pageIndex={pageIndex}
        onPaginationChange={setPageIndex}
      />
    </>
  );
}
