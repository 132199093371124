import React, { useState, useEffect } from "react";
import { Search, Loader2 } from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "./../../components/ui/dialog";
import { Input } from "./../../components/ui/input";
import { Checkbox } from "./../../components/ui/checkbox";
import { ScrollArea } from "./../../components/ui/scroll-area";
import { cn } from "../../lib/utils";
import { useGetCompanyFleetForScheduleQuery } from "../../api/busLineOfficeApi";
import { useOfficeUserAuth } from "../../api/officeAuth";
import { errorHandler } from "../../error/index";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateVehicleData } from "../../slices/addTimeRefetch";
import { RootState } from "../../slices/types";

interface Vehicle {
  id: string;
  model: string;
  registrationNumber: string;
}

const VehicleSelector = ({
  onSelectionChange,
  isDialogOpen,
  setDialogOpen,
}) => {
  const [search, setSearch] = useState("");
  const [selectedVehicles, setSelectedVehicles] = useState(new Set());
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const { companyId } = useOfficeUserAuth();
  const { isLoading, isError, data, error, refetch } =
    useGetCompanyFleetForScheduleQuery(companyId);
  const { updateVehicleDataRefetch } = useSelector(
    (state: RootState) => state.updateVehicleDataRefetch
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.fleet?.vehicles) {
      setVehicles(data.fleet.vehicles);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      if (updateVehicleDataRefetch) {
        await refetch();
      }
      dispatch(setUpdateVehicleData({ updateVehicleDataRefetch: false }));
    })();
  });

  const filteredVehicles = React.useMemo(() => {
    return vehicles.filter((vehicle) =>
      vehicle.registrationNumber.toLowerCase().includes(search.toLowerCase())
    );
  }, [vehicles, search]);

  const handleCheckboxChange = (vehicleId: string) => {
    setSelectedVehicles((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(vehicleId)) {
        newSelected.delete(vehicleId);
      } else {
        newSelected.add(vehicleId);
      }
      return newSelected;
    });
  };

  const handleConfirm = () => {
    const selectedVehiclesList = vehicles.filter((v) =>
      selectedVehicles.has(v.id)
    );
    onSelectionChange?.(selectedVehiclesList);
    setDialogOpen(false);
  };

  useEffect(() => {
    if (isError && error) {
      errorHandler(error);
    }
  }, [error, isError]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="w-full justify-start text-left font-normal"
        >
          <Search className="mr-2 h-4 w-4" />
          Select Vehicles ({selectedVehicles.size} selected)
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select Vehicles</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <Search className="h-4 w-4 text-gray-500" />
            <Input
              placeholder="Search vehicles..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="flex-1"
            />
          </div>
          <ScrollArea className="h-72 rounded-md border p-2">
            {filteredVehicles.length > 0 ? (
              <div className="space-y-2">
                {filteredVehicles.map((vehicle) => (
                  <div
                    key={vehicle.id}
                    className={cn(
                      "flex items-center space-x-2 rounded-lg p-2",
                      "hover:bg-accent hover:text-accent-foreground"
                    )}
                  >
                    <Checkbox
                      id={`vehicle-${vehicle.id}`}
                      checked={selectedVehicles.has(vehicle.id)}
                      onCheckedChange={() => handleCheckboxChange(vehicle.id)}
                    />
                    <label
                      htmlFor={`vehicle-${vehicle.id}`}
                      className="flex-1 cursor-pointer"
                    >
                      <div className="font-medium">
                        {vehicle.registrationNumber}
                      </div>
                      <div className="text-sm text-gray-500">
                        {vehicle.model}
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex h-full items-center justify-center text-sm text-gray-500">
                No vehicles found
              </div>
            )}
          </ScrollArea>
        </div>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <Button
            onClick={handleConfirm}
            className="bg-green-700"
            variant="outline"
          >
            Confirm ({selectedVehicles.size} selected)
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <Loader2 className="h-8 w-8 animate-spin" />
    <span className="ml-2">Loading...</span>
  </div>
);

export default VehicleSelector;
