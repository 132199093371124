import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useGetCompanyFleetFromOfficeQuery } from "../../api/busLineOfficeApi";
import { errorHandler } from "../../error/index";
import { useOfficeUserAuth } from "../../api/officeAuth";
import { setVehicleId } from "../../slices/vehicleId";
import { RootState } from "../../slices/types";
import { setRefetchTicketsPage } from "../../slices/refetchSlice";
import { useLazyVehiclesLeavingAtSomeSpecificTimeQuery } from "../../api/busLineOfficeApi";

interface Vehicle {
  id: string;
  registrationNumber: string;
}

interface Fleet {
  vehicles: Vehicle[];
}

interface CompanyFleetResponse {
  fleet: Fleet;
}

interface vehicleData {
  vehicle: {
    id: string;
    registrationNumber: string;
  }
 
}

interface VehicleInputDropdownProps {
  value: string;
  onValueChange: (value: string) => void;
  placeHolder: string;
  setHasVehicle: (value: boolean) => void;
  time?: string;
}

export default function VehicleInputDropdown({
  value,
  onValueChange,
  placeHolder,
  setHasVehicle,
  time,
}: VehicleInputDropdownProps) {
  const { companyId } = useOfficeUserAuth();
  const { refetchTicketsPage } = useSelector(
    (state: RootState) => state.refetchTicketsPage
  );

  const {
    isError,
    error,
    data: fleetData,
    refetch,
  } = useGetCompanyFleetFromOfficeQuery(companyId!, {
    skip: !companyId,
  });
  const [
    getVehicleLeavingData,
    {
      isError: isVehicleLeavingError,
      error: vehicleLeavingError,
      data: vehicleLeavingData,
    },
  ] = useLazyVehiclesLeavingAtSomeSpecificTimeQuery();

  useEffect(() => {
    if (time) {
      getVehicleLeavingData({ time });
    }
  }, [getVehicleLeavingData, time]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isVehicleLeavingError && vehicleLeavingError) {
      errorHandler(vehicleLeavingError);
    }
  }, [isVehicleLeavingError, vehicleLeavingError]);

  useEffect(() => {
    if (isError && error) {
      errorHandler(error);
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      if (refetchTicketsPage) {
        await refetch();
        dispatch(setRefetchTicketsPage({ refetchTicketsPage: false }));
      }
    })();
  }, [dispatch, refetch, refetchTicketsPage]);

  const fleet = fleetData as CompanyFleetResponse | undefined;

  const handleVehicleChange = (selectedValue: string) => {
    if (!selectedValue) return;

    onValueChange(selectedValue);

    // Only proceed if we have fleet data and vehicles
    if (fleet?.fleet?.vehicles) {
      const selectedVehicle = fleet.fleet.vehicles.find(
        (vehicle: Vehicle) => vehicle.registrationNumber === selectedValue
      );

      if (selectedVehicle) {
        dispatch(setVehicleId(selectedVehicle.id));
      }
    }
  };

  // Check if we have any vehicles to display
  const hasVehicles = fleet?.fleet?.vehicles && fleet.fleet.vehicles.length > 0;
  setHasVehicle(hasVehicles);

  return (
    <Select value={value} onValueChange={handleVehicleChange}>
      <SelectTrigger>
        <SelectValue placeholder={placeHolder} />
      </SelectTrigger>
      <SelectContent>
        {time !== null ? (
          vehicleLeavingData?.scheduledVehicles.flatMap((singleVehicle) =>
            singleVehicle.vehicles.map((vehicle:vehicleData) => (
              <SelectItem key={vehicle.vehicle.id} value={vehicle.vehicle.registrationNumber}>
                {vehicle.vehicle.registrationNumber}
              </SelectItem>
            ))
          )
        ) : hasVehicles ? (
          fleet.fleet.vehicles.map((singleVehicle) => (
            <SelectItem
              key={singleVehicle.id}
              value={singleVehicle.registrationNumber}
            >
              {singleVehicle.registrationNumber}
            </SelectItem>
          ))
        ) : (
          <SelectItem value="none">No vehicles available</SelectItem>
        )}
      </SelectContent>
    </Select>
  );
}
