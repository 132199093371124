import React from "react";
import { Outlet } from "react-router-dom";
import HomeTopBar from "./HomeTopBar";

export default function HomeLayOut() {
  return (
    <div className="flex flex-col h-screen">
      <HomeTopBar />
      <main className="flex-1 overflow-auto pt-20">
        <Outlet />
      </main>
    </div>
  );
}