import React from "react";
import { useState} from "react";
import { ShipWheel } from "lucide-react";

export default function DynamicSeatComponent({
  capacity,
  selectedSeatsRef,
  onSeatChange, 
  bookedSeats, 
}: {
  capacity: number;
  selectedSeatsRef: string[];
  onSeatChange: (seats: string[]) => void;
  bookedSeats: string[];
}) {
  const seatColor = "bg-blue-600"; 
  const bookedSeatColor = "bg-gray-500"; 
  const emptySeatColor = "bg-[#F8F9FA]"; 
  const labelColor = "text-[#3C404]"; 
  const [, setForceRender] = useState(false);

  const Seat = ({ label }: { label: string }) => {
    const isBooked = bookedSeats.includes(label); 
    const isSelected = selectedSeatsRef.includes(label); 

    const seatStyles = isBooked
      ? bookedSeatColor 
      : isSelected
      ? seatColor 
      : emptySeatColor; 

    return (
      <div
        className={`${seatStyles} w-12 h-12 flex items-center justify-center rounded-md cursor-pointer ${
          isBooked ? "cursor-not-allowed" : "cursor-pointer"
        }`} 
        onClick={() => !isBooked && handleSeatSelect(label)} 
      >
        <span className={`${labelColor} text-sm font-medium`}>{label}</span>
      </div>
    );
  };

  const handleSeatSelect = (seat: string) => {
    let updatedSeats = [...selectedSeatsRef];
    if (!updatedSeats.includes(seat)) {
      updatedSeats.push(seat);
    } else {
      updatedSeats = updatedSeats.filter((s) => s !== seat);
    }

    // Update parent state
    onSeatChange(updatedSeats);
    setForceRender((prev) => !prev); // Force re-render
  };

  const EmptySeat = () => (
    <div
      className={` ml-10 mr-10`}
    />
  );

  // Layout configuration based on capacity
  const getLayout = () => {
    if (capacity === 11) {
      return [
        ["A1", "A2", "wheel"],
        ["B1", "B2", "B3"],
        ["C1", "C2", "C3"],
        ["D1", "D2", "D3"],
      ];
    } else if (capacity === 2) {
      return [
        ["A1", "A2", "wheel"],] 
    } else if (capacity === 1) {
      return [
        ["A1", "wheel"],]  
    }
    else if (capacity === 14) {
      return [
        ["A1", "A2", "wheel"],
        ["B1", "B2", "B3"],
        ["C1", "C2", "C3"],
        ["D1", "D2", "D3"],
        ["E1", "E2", "E3"],
      ];
    } else if (capacity === 45) {
      return [
        ["", "", "", "", "wheel"],
        ["A1", "A2", "", "A3", "A4"],
        ["B1", "B2", "", "B3", "B4"],
        ["C1", "C2", "", "C3", "C4"],
        ["D1", "D2", "", "D3", "D4"],
        ["E1", "E2", "", "E3", "E4"],
        ["F1", "F2", "", "F3", "F4"],
        ["G1", "G2", "", "G3", "G4"],
        ["H1", "H2", "", "H3", "H4"],
        ["J1", "J2", "", "J3", "J4"],
        ["K1", "K2", "", "K3", "K4"],
        ["L1", "L2", "L3", "L4", "L5"],
      ];
    } else {
      return [];
    }
  };
  const layout = getLayout();
  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-xs mx-auto">
      <h2 className="text-lg font-semibold mb-4 text-center text-[#3C4043]">
        Select seat
      </h2>
      <div
        className={`grid gap-2 mb-4 ${
          capacity === 45 ? "grid-cols-5" : "grid-cols-3"
        }`}
      >
        {layout.map((row, rowIndex) =>
          row.map((item, colIndex) => {
            if (item === "wheel") {
              return (
                <ShipWheel
                  key={`wheel-${rowIndex}-${colIndex}`}
                  className="w-8 h-8 text-[#3C4043]"
                />
              );
            } else if (item === "") {
              return <EmptySeat  />;
            } else {
              return <Seat key={item} label={item} />;
            }
          })
        )}
      </div>
    </div>
  );
}
