import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../slices/types";
import { BASE_URL, LOCAL_BASEURL } from "../constants/constants";

export const busLineUserApi = createApi({
  reducerPath: "busLineUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // baseUrl: LOCAL_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const { token } = state.userAuth;
      if (token) {
        headers.set("token", token);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDriverVehicleId: builder.query({
      query: (email) => ({
        url: `/get-vehicleId?email=${email}`,
        method: "GET",
      }),
    }),

    postDriverSubmitVehicleReport: builder.mutation({
      query: ({ driverReportData }) => ({
        url: "/submitVehicleReport",
        method: "POST",
        body: driverReportData,
      }),
    }),
    // getSpecificDriverReports: builder.query({
    //   query: ({ userId, formattedDate }) => ({
    //     url: `/get-driverReports?driverId=${userId}&date=${formattedDate}`,
    //     method: "GET",
    //   }),
    // }),
    getDriverIncome: builder.query({
      query: ({ vehicleId, dateString }) => ({
        url: `/get-driverIncome?vehicleId=${vehicleId}&date=${dateString}`,
        method: "GET",
      }),
    }),
    getSpecificDriverReports: builder.query({
      query: ({ userId, formattedDate }) => {
        let url = `/get-driverReports?driverId=${userId}`;
        if (formattedDate) url += `&date=${formattedDate}`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    postDriverEndTrip: builder.mutation({
      query: ({ vehicleId, driverId, vehicleCapacity }) => ({
        url: "/end-trip",
        method: "POST",
        body: {
          vehicleId,
          driverId,
          vehicleCapacity: parseInt(vehicleCapacity),
        },
      }),
    }),
    getOwnerData: builder.query({
      query: (ownerEmail) => ({
        url: `/owner/get-report?email=${ownerEmail}`,
        method: "GET",
      }),
    }),
    getOwnerSpecificVehicle: builder.query({
      query: ({ email, selectedVehicle, formattedDate }) => ({
        url: `/owner/get-specific-vehicle-report?email=${email}&regNo=${selectedVehicle}&date=${formattedDate}`,
        method: "GET",
      }),
    }),
    postDriverSharesContribution: builder.mutation({
      query: ({ driverId, vehicleId, sharesPaid }) => ({
        url: `/pay-driver-shares`,
        method: "POST",
        body: {
          vehicleId,
          driverId,
          sharesPaid: parseInt(sharesPaid),
        },
      }),
    }),
  }),
});

export const {
  usePostDriverSharesContributionMutation,
  useGetDriverVehicleIdQuery,
  usePostDriverSubmitVehicleReportMutation,
  useGetSpecificDriverReportsQuery,
  useGetDriverIncomeQuery,
  useLazyGetDriverIncomeQuery,
  usePostDriverEndTripMutation,
  useLazyGetOwnerDataQuery,
  useGetOwnerSpecificVehicleQuery,
} = busLineUserApi;
export const { getDriverVehicleId } = busLineUserApi.endpoints;
