import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ScheduleRefetchState {
  scheduleRefetchSlice: boolean | null;
}

const initialState: ScheduleRefetchState = {
  scheduleRefetchSlice: null,
};

const scheduleRefetchSlice = createSlice({
  name: "scheduleRefetch",
  initialState,
  reducers: {
    setRefetchSlice: (state, action: PayloadAction<boolean>) => {
      state.scheduleRefetchSlice = action.payload;
    },
  },
});

export const { setRefetchSlice } = scheduleRefetchSlice.actions;
export default scheduleRefetchSlice.reducer;

