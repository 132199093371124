import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Schedule {
  refetchSchedulePage: boolean | null;
}

const initialState: Schedule = {
    refetchSchedulePage: null,
};

const scheduleSlice = createSlice({
  name: "scheduleSlice",
  initialState,
  reducers: {
    setRefetchSchedulePage: (state, action: PayloadAction<Schedule>) => {
      state.refetchSchedulePage = action.payload.refetchSchedulePage;
    },
  },
});

export const { setRefetchSchedulePage } = scheduleSlice.actions;
export default scheduleSlice.reducer;
