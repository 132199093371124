import React from "react";
import { Dialog, DialogContent, DialogTitle } from "../../components/ui/dialog";
import { DataTable } from "./Re-Usables/data-table";
import {
  columns,
  IndividualOfficeSharesData,
} from "../../custom_components/business_components/IndividualOfficeTripsAndSharesTable/IndividualOffice";
import { ScrollArea } from "../../components/ui/scroll-area";

interface IndividualOfficeModalProps {
  officeName: string;
  totalPages: number;
  pageIndex: number;
  onPaginationChange: (value: number) => void;
  onData: () => IndividualOfficeSharesData[];
  isDialogOpen: boolean;
  closeDialog: (value: boolean) => void;
}

export default function IndividualOfficeModal({
  officeName,
  totalPages,
  pageIndex,
  onPaginationChange,
  onData,
  isDialogOpen,
  closeDialog,
}: IndividualOfficeModalProps) {
  console.log("pejii", totalPages);
  return (
    <Dialog open={isDialogOpen} onOpenChange={closeDialog}>
      <DialogContent className="max-w-[50vw] w-full h-[90vh] flex flex-col gap-0 p-0 bg-customLightBlue overflow-hidden">
        <DialogTitle className="text-2xl p-6 bg-gray-100 flex-shrink-0">
          {officeName} Office Shares Form with Driver Contribution
        </DialogTitle>

        <div className="flex-1 min-h-0">
          <ScrollArea className="h-full">
            <div className="p-6">
              <DataTable
                columns={columns}
                data={onData()}
                onPaginationChange={onPaginationChange}
                pageCount={totalPages}
                pageIndex={pageIndex}
              />
            </div>
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  );
}
