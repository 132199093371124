import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../slices/types";
import { BASE_URL, LOCAL_BASEURL } from "../constants/constants";

export const busLineOfficeApi = createApi({
  reducerPath: "busLineOfficeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // baseUrl: LOCAL_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const { token, companyId } = state.companyAuth;
      if (token) {
        headers.set("token", token);
      }
      headers.set("Content-Type", "application/json");
      headers.set("companyId", companyId || "");
      return headers;
    },
  }),
  tagTypes: ["OfficeData"],
  endpoints: (builder) => ({
    postOfficeLogin: builder.mutation({
      query: ({ officeLoginData }) => ({
        url: "/auth/officeAdminLogin",
        method: "POST",
        body: officeLoginData,
      }),
    }),
    getCompanyFleetFromOffice: builder.query<any, string>({
      query: (companyId) => ({
        url: `/get-company-fleet?companyId=${companyId}`,
        method: "GET",
      }),
    }),
    getOfficeRevenueAndData: builder.query<any, any>({
      query: ({ officeId, page, formattedDate }) => ({
        url: `/get-office-revenue-and-data?officeId=${officeId}&page=${page}&date=${formattedDate}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [{ type: "OfficeData", id: arg.officeId }, "OfficeData"]
          : ["OfficeData"],
    }),
    postAddParcelOffice: builder.mutation({
      query: ({ parcelDataOffice, companyId, officeId, vehicleId }) => ({
        url: `/create-parcel`,
        method: "POST",
        body: {
          senderName: parcelDataOffice.senderName,
          senderPhone: parcelDataOffice.senderPhoneNumber,
          recipientName: parcelDataOffice.recipientName,
          recipientPhone: parcelDataOffice.recipientPhone,
          fromLocation: parcelDataOffice.from,
          toLocation: parcelDataOffice.to,
          fees: parcelDataOffice.charges,
          companyId,
          officeId,
          vehicleId,
        },
      }),
    }),
    postTicketData: builder.mutation({
      query: ({
        ticketData,
        vehicleRegistrationNumber,
        officeName,
        selectedSeats,
        chosenRoute,
      }) => ({
        url: "/create-manual-ticket",
        method: "POST",
        body: {
          passengerName: ticketData.passengerName,
          officeName: officeName,
          phoneNumber: ticketData.phoneNumber,
          // fromDestination: ticketData.from,
          fromDestination: chosenRoute.split("to")[0],
          // toDestination: ticketData.to,
          toDestination: chosenRoute.split("to")[1],
          fare: parseFloat(ticketData.fare),
          vehicleRegistrationNumber: vehicleRegistrationNumber,
          selectedSeats,
        },
        invalidatesTags: ["OfficeData"],
      }),
    }),
    getOfficeParcelData: builder.query<any, string>({
      query: (officeId) => ({
        url: `/get-All-Office-Parcels?officeId=${officeId}`,
        method: "GET",
      }),
    }),
    getSeatVehicleData: builder.query<any, string>({
      query: (vehicleId) => ({
        url: `/get-vehicle-booked-seats?vehicleId=${vehicleId}`,
        method: "GET",
      }),
    }),
    getCompanyRoutesForSelect: builder.query<any, string>({
      query: (companyId) => ({
        url: `/get-company-routes-for-schedule?companyId=${companyId}`,
        method: "GET",
      }),
    }),
    getCompanyFleetForSchedule: builder.query<any, string>({
      query: (companyId) => ({
        url: `/get-company-fleet-for-schedule?companyId=${companyId}`,
        method: "GET",
      }),
    }),
    createNewSchedule: builder.mutation({
      query: ({ time, officeId, routeId, vehicleIds, date }) => ({
        url: `/add-time-vehicle-route`,
        method: "POST",
        body: {
          officeId,
          time,
          date,
          vehicleIds,
          routeId,
        },
      }),
    }),

    getScheduleData: builder.query({
      query: ({ officeId, date }) => ({
        url: `/get-schedule-data?officeId=${officeId}&date=${date}`,
        method: "GET",
      }),
    }),

    addVehicleToSchedule: builder.mutation({
      query: ({ scheduleId, vehicleId }) => ({
        url: `/add-vehicle-to-schedule`,
        method: "POST",
        body: {
          scheduleId,
          vehicleId,
        },
      }),
    }),

    getListOfVehiclesPerSchedule: builder.query({
      query: ({ officeId, scheduleId }) => ({
        url: `/list-of-vehicle-per-schedule?officeId=${officeId}&scheduleId=${scheduleId}`,
        method: "GET",
      }),
    }),

    getScheduleList: builder.query({
      query: ({ officeId, date }) => ({
        url: `/schedule-list-data?officeId=${officeId}&date=${date}`,
        method: "GET",
      }),
    }),

    getActiveScheduleTimes: builder.query({
      query: ({ officeId }) => ({
        url: `/get-active-schedule-times?officeId=${officeId}`,
        method: "GET",
      }),
    }),
    vehiclesLeavingAtSomeSpecificTime: builder.query({
      query: ({ time }) => ({
        url: `/vehicles-leaving-some-time?time=${time}`,
        method: "GET",
      }),
    }),

    updateSchedule: builder.mutation({
      query: ({ scheduleId, vehicleId,officeId }) => ({
        url: "/dispatch-vehicle",
        method: "POST",
        body: {
          scheduleId,
          vehicleId,
          officeId
        },
      }),
    }),
    deleteSpecificSchedule: builder.query({
      query: ({ scheduleId }) => ({
        url: `/delete-specific-schedule?scheduleId=${scheduleId}`,
        method: "DELETE",
      }),
    }),
    getBookingRoute: builder.query({
      query: ({ companyId }) => ({
        url: `/get-company-routes-for-booking?companyId=${companyId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetBookingRouteQuery,
  useLazyDeleteSpecificScheduleQuery,
  useUpdateScheduleMutation,
  useLazyVehiclesLeavingAtSomeSpecificTimeQuery,
  useGetActiveScheduleTimesQuery,
  useGetScheduleListQuery,
  useGetListOfVehiclesPerScheduleQuery,
  useAddVehicleToScheduleMutation,
  useGetScheduleDataQuery,
  useCreateNewScheduleMutation,
  useGetCompanyFleetForScheduleQuery,
  useGetSeatVehicleDataQuery,
  useGetCompanyRoutesForSelectQuery,
  usePostOfficeLoginMutation,
  useGetCompanyFleetFromOfficeQuery,
  useGetOfficeRevenueAndDataQuery,
  usePostAddParcelOfficeMutation,
  useGetOfficeParcelDataQuery,
  usePostTicketDataMutation,
} = busLineOfficeApi;
export const { getCompanyFleetFromOffice } = busLineOfficeApi.endpoints;
