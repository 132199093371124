import React, { useEffect, useState } from "react";
import { CompanyRoutesAndFare, createColumns } from "./routes_fare_column";
import { DataTable } from "../Re-Usables/data-table";
import { useAuth } from "../../../api/getCredentials";
import { useGetCompanyRoutesAndFareQuery } from "../../../api/busLineApiData";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../slices/types";
import { setRouteDeleteRefetchSlice } from "../../../slices/routesDeleteRefetch";
import { setRouteUpdateRefetchSlice } from "../../../slices/routesUpdateSlice";

interface RouteData {
  routeId: string;
  startRouteName: string;
  endRouteName: string;
  startPoint: string;
  endPoint: string;
}
interface CompanyRoutesAndFareTableProps {
  openFareDialog: (routeId: string) => void;
  openDeleteRouteDialog: (routeId: string) => void;
  onUpdateRouteDialog: (updateData: RouteData) => void;
}

export default function CompanyRoutesAndFareTable({
  openFareDialog,
  openDeleteRouteDialog,
  onUpdateRouteDialog,
}: CompanyRoutesAndFareTableProps) {
  const dispatch = useDispatch();
  const { routeDeleteRefetchSlice } = useSelector(
    (state: RootState) => state.routeDelete
  );
  const { routeUpdateRefetchSlice } = useSelector(
    (state: RootState) => state.routeUpdate
  );
  const { companyId, token } = useAuth();
  const [pageIndex, setPageIndex] = useState(0);

  const { isLoading, data, error, isError, refetch } =
    useGetCompanyRoutesAndFareQuery(
      { companyId, page: pageIndex + 1 },
      {
        skip: !token || !companyId,
      }
    );

  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      if (routeDeleteRefetchSlice) {
        await refetch();
      }
      dispatch(setRouteDeleteRefetchSlice(false));
    })();
  });

  useEffect(() => {
    (async () => {
      if (routeUpdateRefetchSlice) {
        await refetch();
      }
      dispatch(setRouteUpdateRefetchSlice(false));
    })();
  });

  const getFareForRoute = (routeId, fareRates) => {
    const fareRate = fareRates.find((rate) => rate.routeId === routeId);
    return fareRate ? `${fareRate.amount} Ksh` : "0.0";
  };

  const prepareTableData = (): CompanyRoutesAndFare[] => {
    if (!data || !data.companyData || !data.companyData.routes.length) {
      return [];
    }

    return data.companyData.routes.map((route) => ({
      routeId: route.id,
      routeName: route.name,
      startPoint: route.startPoint,
      endPoint: route.endPoint,
      fare: getFareForRoute(route.id, data.companyData.fareRates),
    }));
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();
  const columns = createColumns({
    openFareDialog,
    openDeleteRouteDialog,
    onUpdateRouteDialog,
  });

  return (
    <div className="container mx-auto py-10">
      <DataTable
        columns={columns}
        data={tableData}
        pageCount={data?.totalPages ?? 1}
        pageIndex={pageIndex}
        onPaginationChange={setPageIndex}
      />
    </div>
  );
}
