import React from "react";
import { Badge } from "../../../components/ui/badge";
import { timeStampToEastAfricanTime } from "../../utility_components/utility_functions/utilityFunctions";
import { useUpdateScheduleMutation } from "../../../api/busLineOfficeApi";
import { errorHandler } from "../../../error/index";
import { successFunctionDisplay } from "../../../success/success";
import { useDispatch } from "react-redux";
import { setRefetchSlice } from "../../../slices/scheduleRefetch";
import { useOfficeUserAuth } from "../../../api/officeAuth";

const ScheduleBadge = ({
  schedule,
  vehicleId,
}: {
  schedule: {
    status: string;
    scheduleId: string;
    time: string;
  };
  vehicleId: string;
}) => {
  const { officeId } = useOfficeUserAuth();
  const [postData] = useUpdateScheduleMutation();
  const dispatch = useDispatch();

  const handleUpdateStatus = async () => {
    if (schedule.status !== "DISPATCHED") {
      const result = await postData({
        officeId,
        vehicleId,
        scheduleId: schedule.scheduleId,
      });

      if ("data" in result) {
        successFunctionDisplay("Status updated");
        dispatch(setRefetchSlice(true));
      } else {
        return errorHandler(result.error);
      }
    }
  };

  return (
    <Badge
      key={schedule.scheduleId}
      className="flex flex-col items-center bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer"
      onClick={handleUpdateStatus}
    >
      <span className="font-medium">{schedule.status}</span>
      <span className="text-xs">
        {timeStampToEastAfricanTime(schedule.time)}
      </span>
    </Badge>
  );
};

export default ScheduleBadge;
