import React from "react";
import ScheduleListPage from "../business_components/Schedule-List/schedule_list_page";

export default function ScheduleList() {
  return (
    <>
      <div className="container mx-auto">
        <div className="py-0">
          <ScheduleListPage />
        </div>
      </div>
    </>
  );
}
