import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VehicleId {
  vehicleId: string | null;
}

const initialState: VehicleId = {
  vehicleId: null,
};

const vehicleIdSlice = createSlice({
  name: "vehicleId",
  initialState,
  reducers: {
    setVehicleId: (state, action: PayloadAction<string>) => {
      state.vehicleId = action.payload;
      },
      clearVehicleId: (state) => {
          state.vehicleId = null;
      }
  },
});

export const { setVehicleId,clearVehicleId } = vehicleIdSlice.actions;
export default vehicleIdSlice.reducer;
