import React, { useEffect, useState } from "react";
import { createColumns, VehiclesPerSchedule } from "./VehiclePerScheduleColumn";
import { DataTable } from "../Re-Usables/data-table";
import { useGetListOfVehiclesPerScheduleQuery } from "../../../api/busLineOfficeApi";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { RootState } from "../../../slices/types";
import { useSelector, useDispatch } from "react-redux";

interface VehiclePerScheduleProps {
  officeId: string;
  scheduleId: string;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

interface VehicleData {
  vehicle: Vehicle[];
}
interface Vehicle {
  vehicle: {
    registrationNumber: string;
  };
  driver: Driver;
}
interface Driver {
  phoneNumber: string;
}

export default function VehiclePerSchedule({
  officeId,
  scheduleId,
  isOpen,
  setOpen,
}: VehiclePerScheduleProps) {
  const [pageIndex, setPageIndex] = useState(0);
  const { refetchSchedulePage } = useSelector(
    (state: RootState) => state.refetchSchedulePage
  );
  const dispatch = useDispatch();

  const { isLoading, data, error, isError, refetch } =
    useGetListOfVehiclesPerScheduleQuery({ officeId, scheduleId });
  const [newData, setData] = useState<Vehicle[]>([]);

  const handleReorder = (fromIndex: number, toIndex: number) => {
    setData((prevData) => {
      const newData = [...prevData];
      const [movedItem] = newData.splice(fromIndex, 1);
      newData.splice(toIndex, 0, movedItem);
      return newData;
    });
  };

  useEffect(() => {
    if (data?.enhancedObject) {
      setData(data.enhancedObject);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      if (!refetchSchedulePage) {
        await refetch();
      }
    })();
  }, [dispatch, refetch, refetchSchedulePage]);

  const prepareTableData = (): VehiclesPerSchedule[] => {
    if (!data || !data.enhancedObject || !data.enhancedObject.length) {
      return [];
    }

    return data.enhancedObject.flatMap((entity: VehicleData) =>
      entity.vehicle.map((data: Vehicle) => ({
        vehicle: data?.vehicle?.registrationNumber,
        contacts: data.driver.phoneNumber,
      }))
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();
  // const columns = createColumns();

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[85vw] md:max-w-[75vw] lg:max-w-[65vw] max-h-[90vh] p-0">
          <DialogHeader className="px-6 py-4 border-b">
            <DialogTitle className="text-green-900 text-center font-bold text-xl">
              Vehicle Queue
            </DialogTitle>
          </DialogHeader>

          <ScrollArea className="max-h-[calc(90vh-8rem)]">
            <div className="p-6">
              <div className="rounded-md border">
                <DataTable
                  columns={createColumns(handleReorder)}
                  data={tableData}
                  pageCount={1}
                  pageIndex={pageIndex}
                  onPaginationChange={setPageIndex}
                />
              </div>
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
}
