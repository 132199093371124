import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ScheduleDeleteRefetchState {
  scheduleDeleteRefetchSlice: boolean | null;
}

const initialState: ScheduleDeleteRefetchState = {
    scheduleDeleteRefetchSlice: null,
};

const scheduleDeleteRefetchSlice = createSlice({
  name: "scheduleDeleteRefetch",
  initialState,
  reducers: {
    setDeleteRefetchSlice: (state, action: PayloadAction<boolean>) => {
      state.scheduleDeleteRefetchSlice = action.payload;
    },
  },
});

export const { setDeleteRefetchSlice } = scheduleDeleteRefetchSlice.actions;
export default scheduleDeleteRefetchSlice.reducer;

