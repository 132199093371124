import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OfficeUpdateRefetchState {
  officeUpdateRefetchSlice: boolean | null;
}

const initialState: OfficeUpdateRefetchState = {
    officeUpdateRefetchSlice: null,
};

const officeUpdateRefetchSlice = createSlice({
  name: "officeUpdateRefetchSlice",
  initialState,
  reducers: {
    setOfficeUpdateRefetchSlice: (state, action: PayloadAction<boolean>) => {
      state.officeUpdateRefetchSlice = action.payload;
    },
  },
});

export const { setOfficeUpdateRefetchSlice } = officeUpdateRefetchSlice.actions;
export default officeUpdateRefetchSlice.reducer;

