import React from "react";
import { Link } from "react-router-dom";
import landing from "../image/landing.png";
import { Card, CardContent } from "../components/ui/card";

export default function Features() {
  const services: string[] = [
    "Streamlined Free Ticketing System",
    "Automated Book Keeping With Detailed Reporting",
    "Parcels System With Automated Customer Support",
    "Automated Passenger Self Service System and Customer-Service",
  ];
  return (
    <div className="min-h-screen bg-customLightBlue">
      <section className="relative min-h-screen pt-20">
        <div className="absolute inset-0">
          <img
            src={landing}
            alt="Fleet of Blue Buses"
            className="object-cover w-full h-full"
          />
          <div className="absolute inset-0 bg-black/20" />
        </div>

        <div className="relative container mx-auto px-4 sm:px-6 lg:px-8 pt-32 flex flex-col items-center justify-center min-h-[calc(100vh-5rem)]">
          <div className="max-w-4xl mx-auto text-center space-y-10">
            <div className="w-full px-4 py-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {services.map((service, index) => (
                  <Card
                    key={index}
                    className="bg-blue-500/10 backdrop-blur-sm border-blue-200/30 border 
                        hover:bg-blue-500/20 transition-colors duration-300 
                        shadow-lg shadow-blue-500/10"
                  >
                    <CardContent className="flex items-center justify-center h-full p-6">
                      <p className="text-center text-amber-400 font-bold text-base sm:text-lg md:text-xl">
                        {service}
                      </p>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>

            <div>
              <Link
                to="/company-home"
                className="inline-block px-6 py-3 sm:px-8 sm:py-4 text-sm sm:text-lg font-semibold text-amber-400 border-2 border-amber-400 rounded-full hover:bg-amber-400 hover:text-white transition-colors duration-200"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
