import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../components/ui/table";
import { useGetSpecificDriverReportsQuery } from "../../api/busLineUserApi";
import { Button } from "../../components/ui/button";
import { useNormalUserAuth } from "../../api/NormalUserAuth";
import { AddDriverExpense } from "./AddDriverExpense";
import { getDriverVehicleId } from "../../api/busLineUserApi";
import { store } from "../../store/busLineStore";
import { Loader2, PencilIcon, TrashIcon } from "lucide-react";
import { errorHandler } from "../../error/index";
import { dateFormatDayMonthYear } from "../utility_components/utility_functions/utilityFunctions";
import { DatePickerExpense } from "../../components/ui/DatePickerExpense";
import { useDispatch, useSelector } from "react-redux";
import { setDriverRefetch } from "../../slices/driverRefetch";
import { RootState } from "../../slices/types";

interface DriverReport {
  id: string;
  vehicle: {
    registrationNumber: string;
  };
  description: string;
  imageUrl: string | null;
  createdAt: string;
  revenue: number;
}

interface DriverReportsData {
  totalExpenses: number;
  reports: DriverReport[];
}

export default function DriverExpenses(): JSX.Element {
  const { driverRefetch } = useSelector(
    (state: RootState) => state.driverRefetch
  );
  const dispatch = useDispatch();
  const [expDate, setExpDate] = useState<Date | undefined>();
  const { email, userId } = useNormalUserAuth();
  const [isDriverDialogOpen, setIsDriverDialog] = useState(false);

  useEffect(() => {
    (async () => {
      if (driverRefetch) {
        await refetch();
      }
      dispatch(setDriverRefetch({ driverRefetch: false }));
    })();
  });

  const formattedDate = expDate
    ? `${expDate.getFullYear()}-${String(expDate.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(expDate.getDate()).padStart(2, "0")}`
    : "";

  const { isLoading, data, error, isError, refetch } =
    useGetSpecificDriverReportsQuery({ userId, formattedDate });

  useEffect(() => {
    if (email) {
      store.dispatch(getDriverVehicleId.initiate(email));
    }
    if (isError && error) {
      errorHandler(error);
    }
  }, [email, error, isError]);

  useEffect(() => {
    if (formattedDate && email) {
      refetch();
    }
  }, [formattedDate, email, refetch]);

  const handleExpDateChange = useCallback((newDate: Date | undefined) => {
    setExpDate(newDate);
    console.log("Date changed:", newDate);
  }, []);

  const openDialog = () => setIsDriverDialog(true);
  const closeDialog = () => setIsDriverDialog(false);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  return (
    <>
      <div className="mb-3 overflow-x-auto">
        <Button
          className="bg-customDarkBlue text-white mx-auto mt-4 block"
          onClick={openDialog}
        >
          Add Expense
        </Button>
        <div className="mt-3">
          <DatePickerExpense date={expDate} setDate={handleExpDateChange} />
        </div>
      </div>

      <div className="mt-6 overflow-x-auto">
        {renderTotalExpenses(data)}
        {renderExpensesTable(data)}
      </div>

      {isDriverDialogOpen && (
        <AddDriverExpense isOpen={isDriverDialogOpen} onClose={closeDialog} />
      )}
    </>
  );
}

function renderTotalExpenses(
  data: DriverReportsData | undefined
): JSX.Element | null {
  if (!data || !data.totalExpenses) return null;

  return (
    <div
      style={{
        backgroundColor: "#7485BF",
        color: "white",
        textAlign: "center",
        padding: "10px",
        fontSize: "30px",
      }}
    >
      Total Expenditure: Ksh {data.totalExpenses.toFixed(2) ?? "0.00"}
    </div>
  );
}

function renderExpensesTable(data: DriverReportsData | undefined): JSX.Element {
  return (
    <div className="inline-block w-full align-middle">
      <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
        <Table className="min-w-full md:min-w-[800px] lg:min-w-[1000px] divide-y divide-gray-200 bg-customLightBlue">
          <TableHeader>
            <TableRow>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Vehicle Reg No
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Expense
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Receipt Photo
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount Spent
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="bg-white divide-y divide-gray-200">
            {renderTableRows(data)}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

function renderTableRows(data: DriverReportsData | undefined): JSX.Element {
  if (!data || !data.reports || data.reports.length === 0) {
    return (
      <TableRow>
        <TableCell
          colSpan={6}
          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
        >
          No Driver Reports found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {[...data.reports].reverse().map((report) => (
        <TableRow key={report.id} className="hover:bg-gray-50">
          <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {report.vehicle.registrationNumber}
          </TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {report.description}
          </TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {report.imageUrl ? (
              <img
                src={report.imageUrl}
                alt="Report"
                className="h-10 w-10 object-cover rounded"
              />
            ) : (
              "No Image"
            )}
          </TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {dateFormatDayMonthYear(report.createdAt)}
          </TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            Ksh: {report.revenue}
          </TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <Button variant="ghost" className="mr-2">
              <PencilIcon className="h-5 w-5" aria-hidden="true" />
              Update
            </Button>
            <Button variant="ghost" color="red">
              <TrashIcon className="h-5 w-5" aria-hidden="true" /> Delete
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
