import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogCancel,
} from "../../components/ui/alert-dialog";
import { errorHandler } from "../../error/index";
import { successFunctionDisplay } from "../../success/success";
import { useLazyDeleteSpecificScheduleQuery } from "../../api/busLineOfficeApi";
import { useDispatch } from "react-redux";
import { setDeleteRefetchSlice } from "../../slices/scheduleDeleteRefetch";

interface DeleteDialogProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  scheduleId: string;
}

export default function DeleteScheduleDialog({
  isOpen,
  setOpen,
  scheduleId,
}: DeleteDialogProps) {
  const dispatch = useDispatch();
  const [deleteSchedule,] =
    useLazyDeleteSpecificScheduleQuery();
  const handleDelete = async () => {
    const fireDeleteAction = await deleteSchedule({ scheduleId });
    if ("data" in fireDeleteAction) {
      successFunctionDisplay("Schedule and related associations deleted successfully.");
      dispatch(setDeleteRefetchSlice(true))
    } else {
      return errorHandler(fireDeleteAction.error);
    }
  }
  return (
    <>
      <AlertDialog open={isOpen} onOpenChange={setOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-2xl text-red-600">
              Delete?...!
            </AlertDialogTitle>
            <AlertDialogDescription className="text-lg">
              Are you sure you wanna complete this action?.The action cannot be
              undone once completed.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className={
                "bg-destructive hover:bg-destructive/80 text-destructive-foreground"
              }
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
