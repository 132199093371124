import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RoutesDeleteRefetchState {
  routeDeleteRefetchSlice: boolean | null;
}

const initialState: RoutesDeleteRefetchState = {
    routeDeleteRefetchSlice: null,
};

const routeDeleteRefetchSlice = createSlice({
  name: "routeDeleteRefetchSlice",
  initialState,
  reducers: {
    setRouteDeleteRefetchSlice: (state, action: PayloadAction<boolean>) => {
      state.routeDeleteRefetchSlice = action.payload;
    },
  },
});

export const { setRouteDeleteRefetchSlice } = routeDeleteRefetchSlice.actions;
export default routeDeleteRefetchSlice.reducer;

