import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { useLazyUpdateSpecificCompanyRouteQuery } from "../../api/busLineApiData";
import { errorHandler } from "../../error";
import { successFunctionDisplay } from "../../success/success";
import { useDispatch } from "react-redux";
import { setRouteUpdateRefetchSlice } from "../../slices/routesUpdateSlice";

interface RouteData {
  routeId: string;
  startRouteName: string;
  endRouteName: string;
  startPoint: string;
  endPoint: string;
}

interface RouteUpdateDialogProps {
  routeId: string;
  startRouteName: string;
  endRouteName: string;
  startPoint: string;
  endPoint: string;
  isUpdateDialogOpen: boolean;
  setUpdateDialogOpen: (value: boolean) => void;
}

const RouteUpdateDialog: React.FC<RouteUpdateDialogProps> = ({
  startRouteName,
  endRouteName,
  startPoint,
  endPoint,
  routeId,
  isUpdateDialogOpen,
  setUpdateDialogOpen,
}) => {
  const dispatch = useDispatch();
  const [updateRouteFn,] = useLazyUpdateSpecificCompanyRouteQuery();
  const [updatedRoute, setUpdatedRoute] = useState<RouteData>({
    routeId,
    startRouteName,
    endRouteName,
    startPoint,
    endPoint,
  });

  const [errors, setErrors] = useState<{
    startRouteName: string;
    endRouteName: string;
    startPoint: string;
    endPoint: string;
  }>({
    startRouteName: "",
    endRouteName: "",
    startPoint: "",
    endPoint: "",
  });

  useEffect(() => {
    setUpdatedRoute({
      routeId,
      startRouteName,
      endRouteName,
      startPoint,
      endPoint,
    });
  }, [routeId, startRouteName, endRouteName, startPoint, endPoint]);

  const handleInputChange = (field: keyof RouteData, value: string) => {
    setUpdatedRoute((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [field]: value.trim() === "" ? "This field cannot be empty" : "",
    }));
  };

  const validateForm = () => {
    const newErrors = {
      startRouteName:
        updatedRoute.startRouteName.trim() === ""
          ? "Start route name is required"
          : "",
      endRouteName:
        updatedRoute.endRouteName.trim() === ""
          ? "End route name is required"
          : "",
      startPoint:
        updatedRoute.startPoint.trim() === "" ? "Start point is required" : "",
      endPoint:
        updatedRoute.endPoint.trim() === "" ? "End point is required" : "",
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error !== "");
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const result = await updateRouteFn({
        startRouteName: updatedRoute.startRouteName,
        endRouteName: updatedRoute.endRouteName,
        startPoint: updatedRoute.startPoint,
        endPoint: updatedRoute.endPoint,
        routeId: updatedRoute.routeId,
      });
      if ("data" in result) {
        successFunctionDisplay("Route updated successfully");
        setUpdateDialogOpen(false);
        dispatch(setRouteUpdateRefetchSlice(true));
      } else {
        setUpdateDialogOpen(false);
        return errorHandler(result.error);
      }
    }
  };

  return (
    <Dialog open={isUpdateDialogOpen} onOpenChange={setUpdateDialogOpen}>
      <DialogTrigger asChild></DialogTrigger>
      <DialogContent className="bg-customLightBlue p-6 rounded-lg max-w-lg mx-auto">
        <DialogHeader>
          <DialogTitle className="text-center">Update Route</DialogTitle>
        </DialogHeader>
        <div className="w-full max-w-md">
          <Label htmlFor="name" className="text-lg text-center block mb-2">
            Route Name e.g Eldoret to Nairobi
          </Label>
          <div className="flex justify-center items-center mb-4">
            <div className="mr-2">
              <Input
                id="sRouteName"
                placeholder="Starting Route Name"
                name="startRouteName"
                onChange={(e) =>
                  handleInputChange("startRouteName", e.target.value)
                }
                value={updatedRoute.startRouteName}
                className={errors.startRouteName ? "border-red-500" : ""}
              />
              {errors.startRouteName && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.startRouteName}
                </p>
              )}
            </div>
            <p className="px-3">to</p>
            <div className="ml-2">
              <Input
                id="eRouteName"
                placeholder="Ending Route Name"
                name="endRouteName"
                onChange={(e) =>
                  handleInputChange("endRouteName", e.target.value)
                }
                value={updatedRoute.endRouteName}
                className={errors.endRouteName ? "border-red-500" : ""}
              />
              {errors.endRouteName && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.endRouteName}
                </p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <Label htmlFor="startPoint" className="block mb-2">
              Start Point
            </Label>
            <Input
              id="startPoint"
              value={updatedRoute.startPoint}
              onChange={(e) => handleInputChange("startPoint", e.target.value)}
              className={errors.startPoint ? "border-red-500" : ""}
            />
            {errors.startPoint && (
              <p className="text-red-500 text-sm mt-1">{errors.startPoint}</p>
            )}
          </div>
          <div className="mb-4">
            <Label htmlFor="endPoint" className="block mb-2">
              End Point
            </Label>
            <Input
              id="endPoint"
              value={updatedRoute.endPoint}
              onChange={(e) => handleInputChange("endPoint", e.target.value)}
              className={errors.endPoint ? "border-red-500" : ""}
            />
            {errors.endPoint && (
              <p className="text-red-500 text-sm mt-1">{errors.endPoint}</p>
            )}
          </div>
          <div className="flex justify-center">
            <Button
              onClick={handleSubmit}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              Update Route
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RouteUpdateDialog;
