import React, { useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../components/ui/table";
import { useGetOfficeParcelDataQuery } from "../../api/busLineOfficeApi";
import { Loader2 } from "lucide-react";
import { errorHandler } from "../../error/index";
import { useOfficeUserAuth } from "../../api/officeAuth";
import { dateFormatDayMonthYear } from "../utility_components/utility_functions/utilityFunctions";

interface Vehicle {
  registrationNumber: string;
}

interface Parcel {
  id: string;
  senderName: string;
  recipientName: string;
  fromLocation: string;
  toLocation: string;
  fees: number;
  recipientPhone: string;
  sentAt: string;
  vehicle: Vehicle;
  status: string;
}

interface OfficeParcelData {
  totalParcelRevenue: number;
  specificOffice: {
    parcel: Parcel[];
  };
}

interface ParcelViewOfficeProps {
  openDialog: (type: string) => void;
}

export default function ParcelViewOffice({
  openDialog,
}: ParcelViewOfficeProps): JSX.Element {
  const { officeId } = useOfficeUserAuth();
  const {
    isLoading: getParcelLoading,
    data: parcels,
    isError: isGetParcelError,
    error: parcelErrorMessage,
    isSuccess,
  } = useGetOfficeParcelDataQuery(officeId);

  useEffect(() => {
    if (isGetParcelError && parcelErrorMessage) {
      errorHandler(parcelErrorMessage);
    }
  }, [isGetParcelError, parcelErrorMessage]);

  if (getParcelLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  return (
    <>
      <Button
        variant="outline"
        className="mt-4 bg-[#EEF1FE] px-4 py-2 text-sm"
        onClick={() => openDialog("parcel")}
      >
        New Parcel
      </Button>
      <div className="mt-6 overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
            {parcels && parcels.totalParcelRevenue && (
              <div
                style={{
                  backgroundColor: "#7485BF",
                  color: "white",
                  textAlign: "center",
                  padding: "10px",
                  fontSize: "30px",
                }}
              >
                Total Parcel Sales: Ksh{" "}
                {parcels.totalParcelRevenue.toFixed(2) ?? "0.00"}
              </div>
            )}
            <Table className="min-w-full md:min-w-[1000px] lg:min-w-[1200px] divide-y divide-gray-200 bg-customLightBlue">
              <TableHeader>
                <TableRow>
                  {[
                    "Parcel ID",
                    "Sender Name",
                    "Recipient Name",
                    "Destination (From)",
                    "Destination (To)",
                    "Fee Charges",
                    "Recipient Phone",
                    "Time Stamp",
                    "Vehicle Number",
                    "Status",
                  ].map((header) => (
                    <TableHead
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody className="bg-white divide-y divide-gray-200">
                {renderTableRows(parcels, isSuccess)}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

function renderTableRows(
  parcels: OfficeParcelData | undefined,
  isSuccess: boolean
): JSX.Element {
  if (
    isSuccess &&
    parcels?.specificOffice?.parcel &&
    parcels.specificOffice.parcel.length > 0
  ) {
    return (
      <>
        {[...parcels.specificOffice.parcel].reverse().map((singleParcel) => (
          <TableRow key={singleParcel.id} className="hover:bg-gray-50">
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {singleParcel.id}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {singleParcel.senderName}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {singleParcel.recipientName}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {singleParcel.fromLocation}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {singleParcel.toLocation}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {singleParcel.fees}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {singleParcel.recipientPhone}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {dateFormatDayMonthYear(singleParcel.sentAt)}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {singleParcel.vehicle.registrationNumber}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {singleParcel.status}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  } else {
    return (
      <TableRow>
        <TableCell
          colSpan={10}
          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
        >
          No parcels found
        </TableCell>
      </TableRow>
    );
  }
}
