import React, { useState, useEffect } from "react";
import { Search, Loader2 } from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "./../../components/ui/dialog";
import { Input } from "./../../components/ui/input";
import { Checkbox } from "./../../components/ui/checkbox";
import { ScrollArea } from "./../../components/ui/scroll-area";
import { cn } from "../../lib/utils";

interface VehicleData {
  id: string;
  registrationNumber: string;
  model: string;
  capacity: number;
}

const AvailableVehicles = ({
  onSelectionChange,
  isDialogOpen,
  setDialogOpen,
  vehicleData,
}) => {
  const [search, setSearch] = useState("");
  const [selectedVehicles, setSelectedVehicles] = useState(new Set());
  const [vehicles, setVehicles] = useState<VehicleData[]>([]);

  useEffect(() => {
    if (vehicleData) {
      setVehicles(vehicleData);
    }
  }, [vehicleData]);

  const filteredVehicles = React.useMemo(() => {
    return vehicles.filter((vehicle) =>
      vehicle.registrationNumber.toLowerCase().includes(search.toLowerCase())
    );
  }, [vehicles, search]);

  const handleCheckboxChange = (vehicleId: string) => {
    setSelectedVehicles((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(vehicleId)) {
        newSelected.delete(vehicleId);
      } else {
        newSelected.add(vehicleId);
      }
      return newSelected;
    });
  };

  const handleConfirm = () => {
    const selectedVehiclesList = vehicles.filter((v) =>
      selectedVehicles.has(v.id)
    );
    onSelectionChange?.(selectedVehiclesList);
    setDialogOpen(false);
  };


  return (
    <Dialog open={isDialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="w-full justify-start text-left font-normal"
        >
          <Search className="mr-2 h-4 w-4" />
          Select Vehicles ({selectedVehicles.size} selected)
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select Vehicles</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <Search className="h-4 w-4 text-gray-500" />
            <Input
              placeholder="Search vehicles..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="flex-1"
            />
          </div>
          <ScrollArea className="h-72 rounded-md border p-2">
            {filteredVehicles.length > 0 ? (
              <div className="space-y-2">
                {filteredVehicles.map((vehicle) => (
                  <div
                    key={vehicle.id}
                    className={cn(
                      "flex items-center space-x-2 rounded-lg p-2",
                      "hover:bg-accent hover:text-accent-foreground"
                    )}
                  >
                    <Checkbox
                      id={`vehicle-${vehicle.id}`}
                      checked={selectedVehicles.has(vehicle.id)}
                      onCheckedChange={() => handleCheckboxChange(vehicle.id)}
                    />
                    <label
                      htmlFor={`vehicle-${vehicle.id}`}
                      className="flex-1 cursor-pointer"
                    >
                      <div className="font-medium">
                        {vehicle.registrationNumber}
                      </div>
                      <div className="text-sm text-gray-500">
                        {vehicle.model}
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex h-full items-center justify-center text-sm text-gray-500">
                No vehicles found
              </div>
            )}
          </ScrollArea>
        </div>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <Button
            onClick={handleConfirm}
            className="bg-green-700"
            variant="outline"
          >
            Confirm ({selectedVehicles.size} selected)
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AvailableVehicles;
