import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { useGetCompanyTripsAndSharesQuery } from "../../api/busLineApiData";
import { useAuth } from "../../api/getCredentials";
import { errorHandler } from "../../error/index";
import { Loader2 } from "lucide-react";
import { IndividualOfficeSharesData } from "./IndividualOfficeTripsAndSharesTable/IndividualOffice";
import IndividualOfficeModal from "./IndividualOfficeModal";

interface TripData {
  officeId: string;
  officeName: string;
  tripCount: number;
  totalShares: number;
  vehicleSharesCount: number;
  totalPages: number;
  retrievedData: RetrievedData[];
}
interface RetrievedData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  registrationNumber: string;
  sharesPaid: number;
  dateSharesPaid: string;
}

interface DataRetrieved {
  tripData: TripData[];
  totalTripsAllOffices: number;
  totalSharesAllOffices: number;
}

interface TripsAndSharesProps {
  date: string;
  period: string;
}

export default function TripsAndSharesBusiness({
  date,
  period,
}: TripsAndSharesProps) {
  const { token, companyId } = useAuth();
  const [pageIndex, setPageIndex] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const openDialog = (office: TripData) => {
    setSelectedOffice(office);
    setIsDialogOpen(true);
  };
  const closeDialog = (isOpen: boolean) => setIsDialogOpen(isOpen);
  const {
    error: tripsAndSharesErrorMessage,
    isError: isTripsAndSharesError,
    data: tripsAndSharesData,
    isLoading,
    refetch,
  } = useGetCompanyTripsAndSharesQuery(
    { companyId, date, page: pageIndex + 1, period },
    {
      skip: !token || !companyId,
      refetchOnMountOrArgChange: true,
    }
  );
  useEffect(() => {
    if (isTripsAndSharesError && tripsAndSharesErrorMessage) {
      errorHandler(tripsAndSharesErrorMessage);
    }
  }, [isTripsAndSharesError, tripsAndSharesErrorMessage]);

  useEffect(() => {
    if (companyId && (date || period)) {
      refetch();
    }
  }, [companyId, date, period, refetch]);
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  return (
    tripsAndSharesData && (
      <>
        {tripsAndSharesDataArrangement(
          tripsAndSharesData as DataRetrieved,
          openDialog
        )}
        {selectedOffice && (
          <IndividualOfficeModal
            officeName={selectedOffice.officeName}
            pageIndex={pageIndex}
            onData={() => prepareTableData(selectedOffice)}
            onPaginationChange={setPageIndex}
            totalPages={selectedOffice.totalPages}
            closeDialog={closeDialog}
            isDialogOpen={isDialogOpen}
          />
        )}
      </>
    )
  );
}

function tripsAndSharesDataArrangement(
  tripsAndSharesData: DataRetrieved,
  openDialog: (office: TripData) => void
): JSX.Element {
  const { tripData, totalSharesAllOffices, totalTripsAllOffices } =
    tripsAndSharesData;

  return (
    <>
      {tripData.length === 0 ? (
        <div className="flex-grow flex items-center justify-center p-8">
          <Card className="bg-customLightBlue">
            <CardHeader>
              <h2 className="text-lg font-semibold">Add Offices..</h2>
            </CardHeader>
            <CardContent>
              <h1>No Offices created yet. Create some to view data.</h1>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6 ">
          {tripData.map((trip, index) => (
            <Card key={index} className="relative bg-customLightBlue">
              <CardHeader>
                <CardTitle className="text-sm font-medium text-blue-600">
                  <h1 className="text-lg font-bold">
                    {trip.officeName.toUpperCase()} BOOKING OFFICE
                  </h1>
                </CardTitle>
              </CardHeader>

              <CardContent>
                <p className="text-sm mb-3">
                  <span className="text-blue-600 font-bold text-lg">
                    Trip Count:
                  </span>
                  <span className="text-black text-lg">
                    {" "}
                    {trip.tripCount.toLocaleString()}
                  </span>
                </p>

                <p className="font-bold mt-2 mb-3 text-blue-600">
                  Shares Collected
                </p>

                <p className="font-semibold mb-3">
                  KSH {trip.totalShares.toLocaleString()}
                </p>
              </CardContent>
              <div className="absolute bottom-4 right-4">
                <Button
                  variant="default"
                  className="bg-customDarkBlue"
                  onClick={() => openDialog(trip)}
                >
                  View
                </Button>
              </div>
            </Card>
          ))}
          <div className="flex-grow flex items-start justify-start">
            <Card className="bg-customDarkBlue text-white p-6 max-w-md w-full relative">
              <CardHeader>
                <CardTitle className="text-center">TOTAL</CardTitle>
              </CardHeader>
              <CardContent>
                <p>
                  <span className="text-black text-xl font-bold">
                    Trips Made:{" "}
                  </span>
                </p>
                <p>
                  <span className="text-white font-bold text-lg">
                    {totalTripsAllOffices.toLocaleString()}
                  </span>
                </p>
                <p className="font-bold text-lg mt-3">
                  <span className="text-black">Shares Collected:</span>
                </p>
                <p className="font-bold">
                  KSH {totalSharesAllOffices.toLocaleString()}
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </>
  );
}

function prepareTableData(
  officeTripData: TripData
): IndividualOfficeSharesData[] {
  return officeTripData.retrievedData.map(
    (singleRetrievedItem: RetrievedData) => ({
      firstName: singleRetrievedItem.firstName,
      lastName: singleRetrievedItem.lastName,
      phoneNumber: singleRetrievedItem.phoneNumber,
      registrationNumber: singleRetrievedItem.registrationNumber,
      sharesPaid: singleRetrievedItem.sharesPaid,
      dateSharesPaid: singleRetrievedItem.dateSharesPaid,
    })
  );
}
