import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
export type CompanyOfficesData = {
  name: string;
  address: string;
  city: string;
  officeId: string;
};

type ColumnProps = {
  openPasswordDialog: (officeId: string) => void;
  handleGenPasswordForOffice: (officeId: string) => void;
  handleGetBookingOfficeData: (officeId: string) => void;
  handleDeleteOffice: (officeId: string) => void;
};

export const createColumns = ({
  openPasswordDialog,
  handleGenPasswordForOffice,
  handleGetBookingOfficeData,
  handleDeleteOffice,
}: ColumnProps): ColumnDef<CompanyOfficesData>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected()
            ? true
            : table.getIsSomePageRowsSelected()
            ? "indeterminate"
            : false
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "address",
    header: "Address",
  },
  {
    accessorKey: "city",
    header: "City",
  },

  {
    id: "actions",
    cell: ({ row }) => {
      const companyOffices = row.original;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => openPasswordDialog(companyOffices.officeId)}
            >
              Generate PassKey
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                handleGenPasswordForOffice(companyOffices.officeId)
              }
            >
              View Available Password
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                handleGetBookingOfficeData(companyOffices.officeId)
              }
            >
              Update
            </DropdownMenuItem>
            <DropdownMenuItem
              className="bg-red-500 text-white hover:bg-red-600"
              onClick={() => handleDeleteOffice(companyOffices.officeId)}
            >
              Delete Office
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
