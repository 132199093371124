import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo2 from "../image/logo2.png";

export default function HomeTopBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path
      ? "text-amber-500 font-semibold underline"
      : "text-gray-700 hover:text-gray-900";
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-customLightBlue shadow-md">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex h-20 items-center justify-between">
          {/* Hamburger Menu for Small Screens */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
          <div
            className={`absolute top-20 left-0 w-full bg-white shadow-lg md:static md:flex items-center space-y-4 md:space-y-0 md:space-x-6 md:bg-transparent md:shadow-none ${
              menuOpen ? "block" : "hidden"
            }`}
          >
            <Link
              to="/"
              className={`block text-center ${isActive(
                "/"
              )} text-base lg:text-xl py-2`}
              onClick={() => {
                if (window.innerWidth < 768) setMenuOpen(false); // Close menu only for small screens
              }}
              replace
            >
              Home
            </Link>
            <Link
              to="/features"
              className={`block text-center ${isActive(
                "/features"
              )} text-base lg:text-xl py-2`}
              onClick={() => {
                if (window.innerWidth < 768) setMenuOpen(false);
              }}
              replace
            >
              Features
            </Link>
            <Link
              to="/about"
              className={`block text-center ${isActive(
                "/about"
              )} text-base lg:text-xl py-2`}
              onClick={() => {
                if (window.innerWidth < 768) setMenuOpen(false);
              }}
              replace
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className={`block text-center ${isActive(
                "/contact"
              )} text-base lg:text-xl py-2`}
              onClick={() => {
                if (window.innerWidth < 768) setMenuOpen(false);
              }}
              replace
            >
              Contact Us
            </Link>
            <Link
              to="/help"
              className={`block text-center ${isActive(
                "/help"
              )} text-base lg:text-xl py-2`}
              onClick={() => {
                if (window.innerWidth < 768) setMenuOpen(false);
              }}
              replace
            >
              Help
            </Link>
            <Link
              to="/faqs"
              className={`block text-center ${isActive(
                "/faqs"
              )} text-base lg:text-xl py-2`}
              onClick={() => {
                if (window.innerWidth < 768) setMenuOpen(false);
              }}
              replace
            >
              FAQs
            </Link>
          </div>
          <div className="ml-auto">
            <img
              src={logo2}
              alt="Company Logo"
              className="h-12 w-auto object-cover md:h-16"
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
