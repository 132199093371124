export let BASE_URL = "";

if (process.env.REACT_APP_ENV === "prod") {
  BASE_URL = "https://backend.buslineapp.com";
} else {
  BASE_URL = "https://backend.dev.buslineapp.com";
}
console.log(BASE_URL);

export const LOCAL_BASEURL = "http://localhost:8000";
