import React from "react";
import { Link } from "react-router-dom";
import landing from "../src/image/landing.png";

export default function NewHomePage() {
  return (
    <div className="min-h-screen bg-customLightBlue">
      <section className="relative min-h-screen pt-20">
        <div className="absolute inset-0">
          <img
            src={landing}
            alt="Fleet of Blue Buses"
            className="object-cover w-full h-full"
          />
          <div className="absolute inset-0 bg-black/20" />
        </div>

        <div className="relative container mx-auto px-4 sm:px-6 lg:px-8 pt-32 flex flex-col items-center justify-center min-h-[calc(100vh-5rem)]">
          <div className="max-w-4xl mx-auto text-center space-y-10">
            <div className="space-y-4">
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-amber-400 leading-tight">
                REVOLUTIONIZING LONG-DISTANCE PUBLIC TRANSPORT
              </h1>
              <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-amber-400 leading-relaxed">
                through
              </p>
              <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-amber-400 leading-tight">
                AUTOMATED MANAGEMENT SYSTEM AND SELF-SERVICE INFRASTRUCTURE
              </h2>
            </div>

            <div>
              <Link
                to="/company-home"
                className="inline-block px-6 py-3 sm:px-8 sm:py-4 text-sm sm:text-lg font-semibold text-amber-400 border-2 border-amber-400 rounded-full hover:bg-amber-400 hover:text-white transition-colors duration-200"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
