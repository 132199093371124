import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { useGetCompanyHomePageDataQuery } from "../../api/busLineApiData";
import { useAuth } from "../../api/getCredentials";
import { errorHandler } from "../../error/index";
import { Loader2 } from "lucide-react";

interface OfficeData {
  officeName: string;
  ticketTotalAmount: number;
  ticketCount: number;
  parcelTotalAmount: number;
  parcelCount: number;
}

interface CompanyData {
  totalTicketsPriceCompanyAmount: number;
  totalCompanyTickets: number;
  totalParcelAmount: number;
}

interface AllData {
  data: {
    officesData: OfficeData[];
    companyData: CompanyData;
  };
}

interface SalesPageProps {
  date: string;
  period: string;
}

export default function SalesPage({ date, period }: SalesPageProps) {
  const { token, companyId } = useAuth();
  const {
    error: companyDataErrorMessage,
    isError: isCompanyDataError,
    data: companyData,
    isLoading,
    refetch,
  } = useGetCompanyHomePageDataQuery(
    { companyId, date, period },
    {
      skip: !token || !companyId,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (isCompanyDataError && companyDataErrorMessage) {
      errorHandler(companyDataErrorMessage);
    }
  }, [companyDataErrorMessage, isCompanyDataError]);

  useEffect(() => {
    if (companyId && (date || period)) {
      refetch();
    }
  }, [companyId, date, period, refetch]);



  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  if (!companyData) {
    return <div>No data available</div>;
  }

  return companyData && companyDataArrangement(companyData as AllData);
}

function companyDataArrangement(dataObject: AllData): JSX.Element {
  const { officesData, companyData } = dataObject?.data;
  return (
    <>
      {officesData.length === 0 ? (
        <div className="flex-grow flex items-center justify-center p-8">
          <Card className="bg-customLightBlue">
            <CardHeader>
              <h2 className="text-lg font-semibold">Add Offices..</h2>
            </CardHeader>
            <CardContent>
              <h1>No Offices created yet. Create some to view data.</h1>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          {officesData.map((office, index) => (
            <Card key={index} className="bg-customLightBlue">
              <CardHeader>
                <CardTitle className="text-sm font-medium text-blue-600">
                  <h1 className="text-lg font-bold">
                    {" "}
                    {office.officeName.toUpperCase()} BOOKING OFFICE
                  </h1>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-sm mb-3">
                  <span className="text-blue-600 font-bold  text-lg">
                    Tickets:
                  </span>
                  <span className="text-black  text-lg">
                    {" "}
                    {office.ticketCount}
                  </span>
                </p>

                <p className="font-semibold mb-3">
                  KSH {office.ticketTotalAmount.toLocaleString()}
                </p>
                <p className="text-sm text-gray-500 mt-2 mb-3">
                  <span className="text-blue-600 font-bold text-lg">
                    Parcels:
                  </span>
                  <span className="text-black  text-lg">
                    {" "}
                    {office.parcelCount}
                  </span>
                </p>
                <p className="font-semibold">
                  KSH {office.parcelTotalAmount.toLocaleString()}
                </p>
              </CardContent>
            </Card>
          ))}
             <div className="flex-grow flex items-start justify-start">
             <Card className="bg-customDarkBlue text-white p-6 max-w-md w-full relative">
            <CardHeader>
              <CardTitle className="text-center">TOTAL</CardTitle>
            </CardHeader>
            <CardContent>
              <p>
                <span className="text-black  text-xl font-bold">Tickets: </span>
                <span className="text-white font-bold text-lg">
                  {companyData.totalCompanyTickets.toLocaleString()}
                </span>
              </p>
              <p className="font-semibold text-lg">
                KSH:{" "}
                {companyData.totalTicketsPriceCompanyAmount.toLocaleString()}
              </p>
              <p className="text-sm mt-2 mb-3">
                <span className="text-black font-bold text-lg">Parcels:</span>
                <span className="text-white text-lg font-bold">
                  {" "}
                  KSH {companyData.totalParcelAmount.toLocaleString()}
                </span>
              </p>
            </CardContent>
          </Card>
        </div>
        </div>
      )}
    </>
  );
}
