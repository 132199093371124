import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { usePostDriverSharesContributionMutation } from "../../api/busLineUserApi";
import { errorHandler } from "../../error/index";
import { successFunctionDisplay } from "../../success/success";

interface SharesContributedError {
  sharesContribution?: string;
}

interface SharesContributed {
  sharesContribution: string;
}

interface ContributeShareProps {
  isOpen: boolean;
  onClose: () => void;
  driverId: string;
  vehicleId: string;
}

export default function ContributeShares({
  isOpen,
  onClose,
  driverId,
  vehicleId,
}: ContributeShareProps) {
  const [sharedData, setSharesData] = useState<SharesContributed>({
    sharesContribution: "",
  });
  const [driverSharesData] = usePostDriverSharesContributionMutation({});
  const [errors, setErrors] = useState<SharesContributedError>({});
  const validateForm = () => {
    let newErrors: SharesContributedError = {};

    if (!sharedData.sharesContribution.trim())
      newErrors.sharesContribution = "Shares amount is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setSharesData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const result = await driverSharesData({
        vehicleId,
        driverId,
        sharesPaid: sharedData.sharesContribution,
      });
      if ("data" in result) {
        sharedData.sharesContribution = "";
        successFunctionDisplay("Shares Paid Successfully");
        onClose();
      } else {
        sharedData.sharesContribution = "";
        onClose();
        return errorHandler(result.error);
      }
      onClose();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Enter Shares Contribution..
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue">
              <div>
                <Label htmlFor="vehicleCapacity" className="text-lg">
                  Shares Contribution
                </Label>
                <Input
                  id="sharesContribution"
                  placeholder="Shares Contribution"
                  className="mt-2"
                  type="number"
                  name="sharesContribution"
                  onChange={handleChange}
                    value={sharedData.sharesContribution}
                />
                {errors.sharesContribution && (
                  <span className="text-red-500 text-sm">
                    {errors.sharesContribution}
                  </span>
                )}
              </div>
            </div>
            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-lg"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
