import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { errorHandler } from "../../error";
import { useDispatch } from "react-redux";
import { validateKenyanPhoneNumber } from "../utility_components/utility_functions/utilityFunctions";
import { useLazyUpdateSpecificVehicleInfoQuery } from "../../../src/api/busLineApiData";
import { successFunctionDisplay } from "../../success/success";
import { setVehicleUpdateRefetchSlice } from '../../slices/vehicleUpdateSlice';

interface VehicleDetails {
  collectiveData: {
    model: string;
    registrationNumber: string;
    capacity: number;
    owner: {
      email: string;
      name: string;
      phone: string;
    };
    company: {
      name: string;
    };
    driver: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
    };
  };
}

interface VehicleUpdateDialogProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  vehicleDetails: VehicleDetails;
  vehicleId: string;
}

interface VehicleFormData {
  vehicleRegistrationNumber: string;
  vehicleModel: string;
  vehicleCapacity: string;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  driverFirstName: string;
  driverLastName: string;
  driverEmail: string;
  driverPhone: string;
}

interface VehicleFormErrors {
  [key: string]: string;
}

const VehicleUpdateDialog: React.FC<VehicleUpdateDialogProps> = ({
  isOpen,
  onClose,
  vehicleDetails,
  vehicleId,
}) => {
  const dispatch = useDispatch()
  const [updateVehicle] = useLazyUpdateSpecificVehicleInfoQuery();
  const [errors, setErrors] = useState<VehicleFormErrors>({});
  const [formData, setFormData] = useState<VehicleFormData>({
    vehicleRegistrationNumber: "",
    vehicleModel: "",
    vehicleCapacity: "",
    ownerName: "",
    ownerEmail: "",
    ownerPhone: "",
    driverFirstName: "",
    driverLastName: "",
    driverEmail: "",
    driverPhone: "",
  });

  useEffect(() => {
    if (vehicleDetails && vehicleDetails.collectiveData) {
      const { collectiveData } = vehicleDetails;
      setFormData({
        vehicleRegistrationNumber: collectiveData.registrationNumber,
        vehicleModel: collectiveData.model,
        vehicleCapacity: collectiveData.capacity.toString(),
        ownerName: collectiveData.owner.name,
        ownerEmail: collectiveData.owner.email,
        ownerPhone: collectiveData.owner.phone,
        driverFirstName: collectiveData.driver.firstName,
        driverLastName: collectiveData.driver.lastName,
        driverEmail: collectiveData.driver.email,
        driverPhone: collectiveData.driver.phoneNumber,
      });
    }
  }, [vehicleDetails, isOpen]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "number" ? Number(value) : value,
    }));
  };

  const validateForm = () => {
    let newErrors: VehicleFormErrors = {};
    const safeStringValue = (value: any) => {
      return value !== undefined && value !== null ? String(value).trim() : "";
    };

    const registrationNumber = safeStringValue(
      formData.vehicleRegistrationNumber
    );
    const model = safeStringValue(formData.vehicleModel);
    const capacity = safeStringValue(formData.vehicleCapacity);
    const ownerEmail = safeStringValue(formData.ownerEmail);
    const ownerName = safeStringValue(formData.ownerName);
    const ownerPhone = safeStringValue(formData.ownerPhone);
    const driverEmail = safeStringValue(formData.driverEmail);
    const driverFirstName = safeStringValue(formData.driverFirstName);
    const driverLastName = safeStringValue(formData.driverLastName);
    const driverPhone = safeStringValue(formData.driverPhone);

    if (!registrationNumber)
      newErrors.vehicleRegistrationNumber = "Vehicle Reg Number is Required";

    if (!model) newErrors.vehicleModel = "Vehicle model is Required";

    if (!capacity) newErrors.vehicleCapacity = "Vehicle capacity is required";
    else if (!/^\d+$/.test(capacity))
      newErrors.vehicleCapacity = "Vehicle capacity must contain only digits";

    if (!ownerEmail) newErrors.ownerEmail = "Owner email is required";
    else if (!/\S+@\S+\.\S+/.test(ownerEmail))
      newErrors.ownerEmail = "Email is invalid";

    if (!ownerName) newErrors.ownerName = "Owner name is required";

    if (!driverEmail) newErrors.driverEmail = "Driver email is required";
    if (!driverFirstName)
      newErrors.driverEmail = "Driver first name is required";
    if (!driverLastName) newErrors.driverEmail = "Driver last name is required";

    if (!ownerPhone) newErrors.ownerPhone = "Owner phone number is required";
    else if (!/^[\d+]+$/.test(ownerPhone))
      newErrors.ownerPhone = "Phone number must contain only digits";
    else if (!validateKenyanPhoneNumber(ownerPhone))
      newErrors.ownerPhone = "Invalid Phone number.";

    if (!driverPhone) newErrors.driverPhone = "Driver phone number is required";
    else if (!/^[\d+]+$/.test(driverPhone))
      newErrors.driverPhone = "Phone number must contain only digits";
    else if (!validateKenyanPhoneNumber(driverPhone))
      newErrors.driverPhone = "Invalid Phone number.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const result = await updateVehicle({
        registrationNumber: formData.vehicleRegistrationNumber,
        model: formData.vehicleModel,
        capacity: formData.vehicleCapacity,
        ownerName: formData.ownerName,
        ownerEmail: formData.ownerEmail,
        ownerPhone: formData.ownerPhone,
        driverFirstName: formData.driverFirstName,
        driverLastName: formData.driverLastName,
        driverEmail: formData.driverEmail,
        driverPhone: formData.driverPhone,
        vehicleId,
      });

      if ("data" in result) {
        dispatch(setVehicleUpdateRefetchSlice(true))
        successFunctionDisplay("Vehicle updated..");
        onClose(false);
      } else {
        errorHandler(result.error);
        onClose(false);
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeader>
          <DialogTitle className="text-2xl">
            Update Vehicle Information
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="bg-customLightBlue">
          <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue space-y-4">
            {/* Vehicle Registration Number */}
            <div>
              <Label htmlFor="vehicleRegistrationNumber" className="text-lg">
                Vehicle Registration Number
              </Label>
              <Input
                id="vehicleRegistrationNumber"
                placeholder="Vehicle Registration Number"
                className="mt-2"
                name="vehicleRegistrationNumber"
                value={formData.vehicleRegistrationNumber}
                onChange={handleChange}
              />
              {errors.vehicleRegistrationNumber && (
                <span className="text-red-500 text-sm">
                  {errors.vehicleRegistrationNumber}
                </span>
              )}
            </div>

            {/* Vehicle Model */}
            <div>
              <Label htmlFor="vehicleModel" className="text-lg">
                Vehicle Model
              </Label>
              <Input
                id="vehicleModel"
                placeholder="Vehicle Model"
                className="mt-2"
                name="vehicleModel"
                value={formData.vehicleModel}
                onChange={handleChange}
              />
              {errors.vehicleModel && (
                <span className="text-red-500 text-sm">
                  {errors.vehicleModel}
                </span>
              )}
            </div>

            {/* Vehicle Capacity */}
            <div>
              <Label htmlFor="vehicleCapacity" className="text-lg">
                Vehicle Capacity
              </Label>
              <Input
                id="vehicleCapacity"
                placeholder="Vehicle Capacity"
                className="mt-2"
                type="text"
                name="vehicleCapacity"
                value={formData.vehicleCapacity}
                onChange={handleChange}
              />
              {errors.vehicleCapacity && (
                <span className="text-red-500 text-sm">
                  {errors.vehicleCapacity}
                </span>
              )}
            </div>

            {/* Owner Email */}
            <div>
              <Label htmlFor="ownerEmail" className="text-lg">
                Owner Email
              </Label>
              <Input
                id="ownerEmail"
                placeholder="Owner Email"
                className="mt-2"
                name="ownerEmail"
                value={formData.ownerEmail}
                onChange={handleChange}
              />
              {errors.ownerEmail && (
                <span className="text-red-500 text-sm">
                  {errors.ownerEmail}
                </span>
              )}
            </div>

            {/* Owner Name */}
            <div>
              <Label htmlFor="ownerName" className="text-lg">
                Owner Name
              </Label>
              <Input
                id="ownerName"
                placeholder="Owner Name"
                className="mt-2"
                name="ownerName"
                value={formData.ownerName}
                onChange={handleChange}
              />
              {errors.ownerName && (
                <span className="text-red-500 text-sm">{errors.ownerName}</span>
              )}
            </div>

            {/* Owner Phone */}
            <div>
              <Label htmlFor="ownerPhone" className="text-lg">
                Owner Phone
              </Label>
              <Input
                id="ownerPhone"
                placeholder="Owner Number"
                type="text"
                className="mt-2"
                name="ownerPhone"
                value={formData.ownerPhone}
                onChange={handleChange}
              />
              {errors.ownerPhone && (
                <span className="text-red-500 text-sm">
                  {errors.ownerPhone}
                </span>
              )}
            </div>

            {/* Driver First Name */}
            <div>
              <Label htmlFor="driverFirstName" className="text-lg">
                Driver First Name
              </Label>
              <Input
                id="driverFirstName"
                placeholder="Driver First Name"
                className="mt-2"
                name="driverFirstName"
                value={formData.driverFirstName}
                onChange={handleChange}
              />
            </div>

            {/* Driver Last Name */}
            <div>
              <Label htmlFor="driverLastName" className="text-lg">
                Driver Last Name
              </Label>
              <Input
                id="driverLastName"
                placeholder="Driver Last Name"
                className="mt-2"
                name="driverLastName"
                value={formData.driverLastName}
                onChange={handleChange}
              />
            </div>

            {/* Driver Email */}
            <div>
              <Label htmlFor="driverEmail" className="text-lg">
                Driver Email
              </Label>
              <Input
                id="driverEmail"
                placeholder="Driver Email"
                className="mt-2"
                name="driverEmail"
                value={formData.driverEmail}
                onChange={handleChange}
              />
              {errors.driverEmail && (
                <span className="text-red-500 text-sm">
                  {errors.driverEmail}
                </span>
              )}
            </div>

            {/* Driver Phone */}
            <div>
              <Label htmlFor="driverPhone" className="text-lg">
                Driver Phone
              </Label>
              <Input
                id="driverPhone"
                placeholder="Driver Number"
                type="text"
                className="mt-2"
                name="driverPhone"
                value={formData.driverPhone}
                onChange={handleChange}
              />
              {errors.driverPhone && (
                <span className="text-red-500 text-sm">
                  {errors.driverPhone}
                </span>
              )}
            </div>
          </div>
          <DialogFooter className="mt-6">
            <Button
              type="submit"
              className="w-full bg-customDarkBlue text-white py-2 text-lg"
            >
              Update Vehicle
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default VehicleUpdateDialog;
